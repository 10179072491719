import BankCard from "../../../../components/BankCard.tsx";
import {User} from "../../../../api/AccessControl/User.ts";
import {useEffect, useState} from "react";
import {BackendApi} from "../../../../api/api.ts";
import {CustomerBankAccount} from "../../../../api/CustomerBankAccounts/CustomerBankAccount.ts";
import Shell from "../../Shell.tsx";
import {CustomerBankAccountRepository} from "../../../../api/CustomerBankAccounts/CustomerBankAccountRepository.ts";
import {PlusIcon} from "@heroicons/react/16/solid/index";

export default function BanksPage({currentUser}: { currentUser: User }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [bankAccounts, setBankAccounts] = useState<Array<CustomerBankAccount>>([]);

    const api = new BackendApi(currentUser.getToken())

    async function getBankAccountLogoURL(bankUrl: string): Promise<string> {
        const response = await api.get('bank/logo', {
            bank_id: bankUrl
        })

        const json = await response.json()

        return json.logo_url
    }

    async function onLoad() {
        const repo = new CustomerBankAccountRepository(api)
        const bankAccounts = await repo.getMyBankAccounts(currentUser.businesses[0])

        const bankAccountsWithLogos = await Promise.all(bankAccounts.map(async bankAccount => {
            const logoUrl = await getBankAccountLogoURL(bankAccount.institutionName)
            return bankAccount.withLogoUrl(logoUrl)
        }))

        setBankAccounts(bankAccountsWithLogos)
        setIsLoading(false)
    }

    useEffect(() => {
        onLoad()
    }, []);

    return (
        <Shell currentUser={currentUser} isLoading={isLoading}>
            <h1 className="weight-600 text-3xl mb-6 ">Banksaldo's</h1>
            <div className="flex gap-3 items-center">
                {bankAccounts.map((bankAccount) => (
                        <BankCard CustomerBankAccount={bankAccount}/>
                    )
                )}
                <a title="Voeg nog een bank toe" onClick={() => {
                    alert('Deze functie wordt nog niet ondersteund. Om een bank toe te voegen, neem contact op met hello@pocketcfo.io')
                }} href={`mailto:hello@pocketcfo.io?subject=addBank_REF${currentUser.businesses[0]}`}
                   className="bg-gray-50 h-24 w-24 border-2 border-gray-100 rounded-full hover:border-gray-200 transition flex items-center justify-center mx-16">
                    <PlusIcon className="w-8 h-8 text-gray-400"/>
                </a>
            </div>
        </Shell>
    )
}
