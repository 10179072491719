import {BackendApi} from "../../api/api.ts";

export class UserSettingsService {
    constructor(
        private readonly settings: Record<string, boolean>,
        private readonly api: BackendApi
    ) {
    }

    public getSetting(key: string): boolean {
        if (!this.settings.hasOwnProperty(key))
            throw new Error(`Setting ${key} does not exist`)
        return this.settings[key]
    }

    public async setSetting(key: string, value: boolean) {
        await this.api.post('me/update-setting', {
            setting_name: key,
            setting_value: value
        })

        this.settings[key] = value
    }

    public getAllSettings(): Record<string, boolean> {
        return this.settings
    }
}
