import {User} from "../../../api/AccessControl/User.ts";
import {BackendApi} from "../../../api/api.ts";
import {useEffect, useState, useCallback} from "react";
import LoadingFrame from "../../../components/LoadingFrame.tsx";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function QuinnAvatar({className}) {
    return <svg width="188" height="189" viewBox="0 0 188 189" fill="none"
                xmlns="http://www.w3.org/2000/svg" className={className}>
        <mask id="mask0_179_474" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0"
              width="188" height="189">
            <circle cx="93.7852" cy="94.4653" r="93.7759" fill="#AEFFA7"/>
        </mask>
        <g mask="url(#mask0_179_474)">
            <circle cx="93.7852" cy="94.4653" r="93.7759" fill="#AEFFA7"/>
            <path
                d="M149.953 171.663C149.953 202.686 151.976 227.835 93.7812 227.835C37.6094 227.835 37.6094 202.686 37.6094 171.663C37.6094 140.64 62.7584 115.491 93.7812 115.491C124.804 115.491 149.953 140.64 149.953 171.663Z"
                fill="#7AB7FF"/>
            <path
                d="M117.148 122.585C117.148 136.282 106.263 142.033 92.8359 142.033C79.4088 142.033 68.5239 136.282 68.5239 122.585C68.5239 108.888 79.4088 97.7852 92.8359 97.7852C106.263 97.7852 117.148 108.888 117.148 122.585Z"
                fill="#EFC9A4"/>
            <path
                d="M117.148 122.585C117.148 136.282 106.263 142.033 92.8359 142.033C79.4088 142.033 68.5239 136.282 68.5239 122.585C68.5239 108.888 79.4088 97.7852 92.8359 97.7852C106.263 97.7852 117.148 108.888 117.148 122.585Z"
                fill="#EFC9A4"/>
            <rect x="68.5239" y="66.6187" width="48.6239" height="56.3891" fill="#EFC9A4"/>
            <circle cx="88.4421" cy="56.1725" r="34.4245" fill="#FAD5B2"/>
            <circle cx="88.4421" cy="91.7778" r="34.4245" fill="#FAD5B2"/>
            <rect x="54.0176" y="53.0822" width="68.8491" height="37.5149" fill="#FAD5B2"/>
            <rect x="57.5435" y="62.399" width="15.208" height="3.73964" rx="1.86982"
                  fill="#783300"/>
            <rect x="74.2437" y="83.2005" width="12.9432" height="5.36358" rx="2.68179"
                  fill="#FF8B7B"/>
            <rect x="88.4419" y="62.399" width="15.208" height="3.73964" rx="1.86982"
                  fill="#783300"/>
            <circle cx="67.4592" cy="71.8396" r="3.95384" fill="#003C15"/>
            <circle cx="93.782" cy="71.8396" r="3.95384" fill="#003C15"/>
            <path
                d="M72.3122 33.8073C60.6328 38.1933 56.0074 40.7837 54.2167 54.0627C46.906 42.1265 56.8482 28.4337 68.8386 20.9398C72.4195 18.7018 72.2703 17.8065 74.2098 14.6733C83.5002 7.59495 103.453 20.9398 103.453 20.9398L110.466 18.7018C110.466 18.7018 107.654 22.9302 107.93 23.4763C108.205 24.0224 115.24 23.4763 115.24 23.4763C115.24 23.4763 114.709 24.1521 111.809 25.4159C108.909 26.6796 131.13 38.0312 126.132 55.8532C125.298 58.8266 121.283 56.2781 118.225 55.8532C103.88 53.8603 106.936 35.9182 92.8947 32.3687C85.5996 30.5245 85.442 40.969 72.3122 33.8073Z"
                fill="#783300"/>
            <circle cx="120.388" cy="66.1388" r="11.0236" fill="#FAD5B2"/>
            <circle cx="120.388" cy="77.5405" r="11.0236" fill="#FAD5B2"/>
            <rect x="109.365" y="65.1492" width="22.0471" height="12.0132" fill="#FAD5B2"/>
            <path
                d="M96.0454 100.56C96.0454 104.095 89.7717 106.96 82.0327 106.96C74.2937 106.96 68.02 104.095 68.02 100.56C68.02 97.025 74.2937 100.56 82.0327 100.56C89.7717 100.56 96.0454 97.025 96.0454 100.56Z"
                fill="#FF8B7B"/>
        </g>
    </svg>;
}

interface InsightProps {
    className?: string,
    minimum?: boolean
    currentUser: User
}

interface Insight {
    id: string;
    type: string;
    message: string;
    creator_type: string;
    creator_id: string;
    business_id: string;
    created_at: string;
    updated_at: string;
    is_read: boolean;
}

export default function Insights({className, minimum, currentUser}: InsightProps) {
    const [insights, setInsights] = useState<Insight[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchInsights = useCallback(async () => {
        try {
            const api = new BackendApi(currentUser.getToken())
            const response = await api.get('insights');
            const data = await response.json();
            setInsights(data['insights']);
        } catch (error) {
            console.error('Error fetching insights:', error);
        }
    }, [currentUser]);

    const markInsightsAsRead = useCallback(async (insightIds: string[]) => {
        if (minimum) return;

        try {
            const api = new BackendApi(currentUser.getToken())
            await api.post('insights/mark-insights-read', {insight_ids: insightIds});
            // Update local state to mark insights as read
            setInsights(prevInsights =>
                prevInsights.map(insight =>
                    insightIds.includes(insight.id) ? {...insight, is_read: true} : insight
                )
            );
        } catch (error) {
            console.error('Error marking insights as read:', error);
        }
    }, [currentUser]);

    useEffect(() => {
        setIsLoading(true);
        fetchInsights().then(() => setIsLoading(false));
    }, [fetchInsights]);

    useEffect(() => {
        const unreadInsights = insights?.filter(insight => !insight.is_read);
        if (unreadInsights.length > 0) {
            markInsightsAsRead(unreadInsights?.map(insight => insight.id));
        }
    }, [insights, markInsightsAsRead]);

    const getInsights = () => {
        if (insights.length > 0) return insights;

        return [{
            id: '1',
            type: 'Welkom!',
            message: "Nog geen inzichten voor je bedrijf. We zijn hard bezig met het analyseren van de gegevens die je met ons hebt gedeeld. Laat ons weten welke specifieke inzichten je graag zou willen via de knop hieronder",
            creator_type: 'Quinn',
            creator_id: '',
            business_id: '',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            is_read: true
        }];
    };

    return (
        <>
            <div className={"lg:col-start-3 border-2 border-gray-50 px-8 pb-8 rounded-md overflow-y-auto " + className}>
                <LoadingFrame isLoading={isLoading}>
                    <ul role="list" className="space-y-6">
                        {getInsights().map((insight, insightIdx) => (
                            <li key={insight.id} className="relative flex gap-x-4 pt-8">
                                <div
                                    className={classNames(
                                        (insightIdx === insights.length - 1 || insights.length === 0) ? 'h-12' : '-bottom-6',
                                        'absolute left-0 top-0 flex w-6 justify-center',
                                        (insightIdx === 0) ? '-top-12' : ''
                                    )}
                                >
                                    <div className="w-px bg-gray-200"/>
                                </div>

                                <QuinnAvatar className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"/>

                                <div
                                    className={`flex-auto rounded-md p-3 ring-1 ring-inset ${
                                        insight.id ? 'ring-gray-200' : 'ring-blue-200 bg-blue-50'
                                    } cursor-pointer`}
                                >
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">{insight.type}</h3>
                                    <p className="mt-2 text-sm text-gray-700">{insight.message}</p>
                                    <div className="mt-2 flex justify-between gap-x-4">
                                        <div className="py-0.5 text-xs leading-5 text-gray-500">
                                        <span className="font-medium text-gray-900">
                                            {insight.creator_type}
                                        </span>
                                            {' • '}
                                            {new Date(insight.created_at).toLocaleString()}
                                        </div>
                                        {!insight.is_read && (
                                            <span
                                                className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                                            Nieuw
                                        </span>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </LoadingFrame>
            </div>
        </>
    )
}
