import {FormTodoType} from "./FormTodo.tsx";

export interface Validator<FormDataType> {
    fieldName: string,
    validationFunction: (data: FormDataType) => boolean,
    message: string
}

export function validatorChain<FormDataType>(key: string, data: FormDataType, validators: Array<Validator<FormDataType>>) {
    const results = [];
    if (!data.hasOwnProperty(key) || data[key] === '') {
        results.push({
            success: false,
            message: 'Field is required',
            fieldName: key
        })

        return results
    }

    for (const validator of validators) {
        if (validator.fieldName !== key)
            continue

        if (!validator.validationFunction(data)) {
            results.push({
                success: false,
                message: validator.message,
                fieldName: validator.fieldName
            })
        }
    }

    return results
}

interface ValidationResult {
    success: boolean,
    todos: Array<FormTodoType>
}

export function validateForm<FormDataType>(data: FormDataType, validators: Array<Validator<FormDataType>>): ValidationResult {
    let issueFound = false
    let formTodos: Array<FormTodoType> = []


    for (const [key, value] of Object.entries(data)) {

        const todosForThisField = validatorChain<FormDataType>(key, data, validators)

        if (todosForThisField.length > 0)
            issueFound = true

        formTodos = [...formTodos, ...todosForThisField]
    }


    return {
        success: !issueFound,
        todos: formTodos
    };
}
