import {BaseTransaction} from "../../../api/BaseTransactions/BaseTransaction.ts";
import {InformationCircleIcon} from "@heroicons/react/24/outline/index";
import {CategoriesListBox} from "./CategoriesListBox.tsx";

export function TransactionRow({transaction, onChangeFunc, onInfoClick, readOnly}: {
    transaction: BaseTransaction,
    onChangeFunc: (newCategory: string, oldTransaction: BaseTransaction) => void
    onInfoClick: (transaction: BaseTransaction) => void
    readOnly: boolean
}) {
    return (
        <>
            <tr key={transaction.iban}>
                <td className='relative whitespace-nowrap py-4 pr-4 text-sm font-medium sm:pr-8 lg:pr-8 inline-flex gap-2 items-center text-xs'>
                    <span onClick={() => onInfoClick(transaction)}><InformationCircleIcon
                        className="w-6 h-6 cursor-pointer"/></span>{transaction.iban}
                </td>
                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-8 lg:pr-8 max-w-48 whitespace-pre-wrap text-xs'>{transaction.counterParty}</td>
                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-8 lg:pr-8 max-w-96 whitespace-pre-wrap  text-xs'>{transaction.description}</td>
                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-8 lg:pr-8'>
                    {transaction.flow === "inflow" &&
                        <span className="text-green-600">+{transaction.amount / 100}</span>}
                    {transaction.flow === "outflow" &&
                        <span className="text-red-600">-{transaction.amount / 100}</span>}
                </td>
                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-8 lg:pr-8 max-w-96 whitespace-pre-wrap flex items-start'>{transaction.date}</td>
                {/*<Select name="category" className='mt-3 block w-full appearance-none rounded-lg border-none bg-black/5 py-1.5 px-3 text-sm/6 text-black focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'>*/}
                {/*    <option value="uncategorized">Uncategorized</option>*/}
                {/*    */}
                {/*</Select>*/}
                <td className="text-right">
                    {!readOnly && <CategoriesListBox defaultCat={transaction.category} onChangeFunc={(newCategory: string) => {
                        onChangeFunc(newCategory, transaction)
                    }} flow={transaction.flow}/>}
                    {readOnly &&
                        <span
                            className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1  font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                            <svg viewBox="0 0 6 6" aria-hidden="true"
                                 className={(transaction.flow === 'inflow') ? 'fill-green-500 h-1.5 w-1.5' : 'fill-red-500 h-1.5 w-1.5'}>
                                <circle r={3} cx={3} cy={3}/>
                            </svg>
                            {transaction.category}
                            </span>
                    }

                </td>

            </tr>
        </>
    )
}
