import {useState} from "react";
import {INFLOW_CATEGORIES, OUTFLOW_CATEGORIES} from "./categories.ts";
import {Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions} from "@headlessui/react";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline/index";

export function CategoriesListBox({defaultCat, onChangeFunc, flow}) {
    const [selected, setSelected] = useState(defaultCat)
    const [query, setQuery] = useState("")

    let categories;

    if (flow === 'inflow')
        categories = [...INFLOW_CATEGORIES, "Uncategorized"]

    if (flow === 'outflow')
        categories = [...OUTFLOW_CATEGORIES, "Uncategorized"]

    if (flow === 'both')
        categories = [...OUTFLOW_CATEGORIES, ...INFLOW_CATEGORIES, "Uncategorized"]

    const filteredCategories =
        query === ''
            ? categories
            : categories.filter((category) => {
                return category.toLowerCase().includes(query.toLowerCase())
            })

    return (
        <>
            <Combobox
                as="div"
                className={"w-96 ml-auto"}
                value={defaultCat}
                onChange={(e) => {
                    setSelected(e)
                    onChangeFunc(e)
                }}
            >
                <div className="relative mt-2">


                    <ComboboxInput
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        onChange={(event) => setQuery(event.target.value)}
                        onBlur={() => setQuery('')}
                        displayValue={defaultCat}
                    />
                    <ComboboxButton
                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pr-0 focus:outline-none">
                        <div className="mr-2">
                            {OUTFLOW_CATEGORIES.includes(defaultCat) &&
                                <div className="bg-red-400 h-2 w-2 rounded-full"></div>}
                            {INFLOW_CATEGORIES.includes(defaultCat) &&
                                <div className="bg-green-400 h-2 w-2 rounded-full"></div>}
                            {defaultCat === "Uncategorized" && <div className="bg-gray-400 h-2 w-2 rounded-full"></div>}
                        </div>
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </ComboboxButton>


                    <ComboboxOptions
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredCategories.map((category) => (
                            <ComboboxOption
                                key={category}
                                value={category}
                                className="group relative cursor-default select-none py-2  pr-4 text-gray-900 data-[focus]:bg-blue-500 data-[focus]:text-white flex items-center mx-1 gap-2 rounded-md px-3"
                            >
                                {OUTFLOW_CATEGORIES.includes(category) &&
                                    <div className="bg-red-400 h-2 w-2 rounded-full"></div>}
                                {INFLOW_CATEGORIES.includes(category) &&
                                    <div className="bg-green-400 h-2 w-2 rounded-full"></div>}
                                {category === "Uncategorized" &&
                                    <div className="bg-gray-400 h-2 w-2 rounded-full"></div>}
                                <span>{category}</span>
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>

                </div>
            </Combobox>
        </>


    )
}
