export enum Role {
    Entrepreneur,
    Partner,
    Admin
}

export function RoleEnumFromString(value: string): Role {
    const conversion = {
        'entrepreneur': Role.Entrepreneur,
        'partner': Role.Partner,
        'admin': Role.Admin
    }

    return conversion[value]
}

export function RoleEnumToString(value: number): string {
    const conversion = ['entrepreneur', 'partner', 'admin']
    return conversion[value];
}
