"use client";

import React from "react";
import {type AnimationProps, type HTMLMotionProps, motion,} from "motion/react";


const animationProps = {
    initial: {"--x": "100%", scale: 1},
    animate: {"--x": "-100%", scale: 1},
    transition: {
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 1,
        type: "spring",
        stiffness: 20,
        damping: 15,
        mass: 2,
        scale: {
            type: "spring",
            stiffness: 200,
            damping: 5,
            mass: 0.5,
        },
    },
} as AnimationProps;

interface ShinyButtonProps extends HTMLMotionProps<"button"> {
    children: React.ReactNode;
    className?: string;
    ref?: React.Ref<HTMLButtonElement>;
}

const ShinyButton = React.forwardRef<HTMLButtonElement, ShinyButtonProps>(
    ({children, className, ...props}, ref) => {
        return (
            <motion.button
                ref={ref}
                {...animationProps}
                {...props}
                className={
                    className + "relative rounded-lg px-4 py-1.5  bg-gradient-to-r from-pink-400 to-purple-400 font-medium backdrop-blur-xl transition-shadow duration-300 ease-in-out hover:shadow dark:bg-[radial-gradient(circle_at_50%_0%,hsl(var(--primary)/10%)_0%,transparent_60%)] dark:hover:shadow-[0_0_20px_hsl(var(--primary)/10%)] "}
            >
        <span
            className="relative shadow block size-full text-sm tracking-wide text-[rgb(255,255,255,100%)] dark:font-light dark:text-[rgb(255,255,255,90%)]"
            style={{
                maskImage:
                    "linear-gradient(-75deg,hsl(var(--primary)) calc(var(--x) + 20%),transparent calc(var(--x) + 30%),hsl(var(--primary)) calc(var(--x) + 100%))",
            }}
        >

          {children}
        </span>
                <span
                    style={{
                        mask: "linear-gradient(rgb(0,0,0), rgb(0,0,0)) content-box,linear-gradient(rgb(0,0,0), rgb(0,0,0))",
                        maskComposite: "exclude",
                    }}
                    className="absolute inset-0 z-10 block rounded-[inherit] bg-[linear-gradient(-75deg,hsl(var(--primary)/10%)_calc(var(--x)+20%),hsl(var(--primary)/50%)_calc(var(--x)+25%),hsl(var(--primary)/10%)_calc(var(--x)+100%))] p-px"
                ></span>
            </motion.button>
        );
    },
);

ShinyButton.displayName = "ShinyButton";

export default ShinyButton;
