import {useSearchParams} from "react-router-dom";
import {UnauthenticatedBackendApi} from "../api/UnauthenticatedApi.ts";
import {useEffect} from "react";

export default function URLCallbackRegisterer() {
    const api = new UnauthenticatedBackendApi();

    const [params, setParams] = useSearchParams();

    function onMount() {
        const searchParams = [];

        for (const [key, value] of params) {
            searchParams.push({
                name: key,
                value: value
            })
        }

        const body = {
            'callback_url': window.location.href,
            'called_back_on': new Date().toISOString(),
            'parameters': searchParams,
        }


        api.post('register-callback', body)
    }


    useEffect(() => {
        onMount()
    }, [])

    return (
        <>
            <p>Callback registered. Thanks!</p>
            <p><a href={'/'} className="text-blue-600">Go back to the app</a></p>
        </>
    )
}
