import {Link} from "react-router-dom";

export function StepThree() {
    return (
        <div className="lg:pt-4">
            <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-primary">Congrats 🎉</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">So what's next?</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                    You just created your PocketCFO account. With this account you can log into the product. You have
                    received an email from us to verify your email.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">

                    <div className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth={1.5} stroke="currentColor"
                                 className="absolute left-1 top-1 h-5 w-5 text-primary">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>

                            Check inbox and verify your email.
                        </dt>
                        <dd className="inline"><br/>Pro tip: check the spam box</dd>
                    </div>
                    <div className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth={1.5} stroke="currentColor"
                                 className="absolute left-1 top-1 h-5 w-5 text-primary">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>

                            After verifying your email, sign in.
                        </dt>
                        <dd className="inline"><br/> You can do that here:
                            <Link className="ml-2 text-base text-primary rounded-md"
                                  to={'/sign-up'}>Sign in
                            </Link>
                        </dd>
                    </div>
                    <div className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth={1.5} stroke="currentColor"
                                 className="absolute left-1 top-1 h-5 w-5 text-primary">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>

                            Connect one or more bank accounts
                        </dt>
                        <dd className="inline"><br/>You'll learn more about this after you log in!</dd>
                    </div>

                </dl>
            </div>
        </div>
    )
}
