
export function confirming(message: string, onConfirm: () => void, onCancel: () => void) {
    const confirmation = window.confirm(message)

    if (confirmation) {
        onConfirm()
    } else {
        onCancel()
    }
}
