import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent
} from "../../../../../components/ui/chart.tsx";
import {Bar, CartesianGrid, ComposedChart, XAxis} from "recharts";
import {FilterOperation} from "../TransactionsPage/FilterService.ts";

const chartConfig = {
    outflow: {
        label: "uitgaven",
        color: "#EF233C",
    },
    inflow: {
        label: "inkomsten",
        color: "#1ec43f",
    },
} satisfies ChartConfig

export default function MonthlyInflowOutflowGraph({data, className}) {

    if (!data) return;
    const reversedData = [...data].reverse();

    function drillToTransactions(data, flow) {
        console.log(data)
        const rawMonth = data.month
        const month = new Date(rawMonth)
        month.setMonth(month.getMonth() + 1);

        const nextMonth = new Date(rawMonth)
        nextMonth.setMonth(month.getMonth() + 1);

        const dateFilter = {
            id: 'filterBookingDate',
            operation: FilterOperation.BETWEEN,
            columnId: 'bookingDate',
            values: [`${month.getFullYear()}-${month.getMonth()}-${month.getDate()}`,
                `${nextMonth.getFullYear()}-${nextMonth.getMonth()}-${nextMonth.getDate()}`]
        }

        const flowFilter = {
            id: 'filterFlow',
            operation: FilterOperation.EQUALS,
            columnId: 'flow',
            values: [flow]
        }

        window.location.href = `/transactions?${new URLSearchParams({filters: JSON.stringify([dateFilter, flowFilter])})}`
    }

    return (

            <div
                className={"p-8 rounded-lg bg-white md:grid-cols-3 border-2 border-gray-50 w-full " + (className || " ")}>

                <ChartContainer config={chartConfig} className="h-[100%] w-full">
                    <ComposedChart accessibilityLayer data={reversedData}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="display_date"
                            tickLine={false}
                            tickMargin={10}
                            axisLine={false}
                        />

                        <ChartTooltip content={<ChartTooltipContent />} />
                        <ChartLegend content={<ChartLegendContent />} />
                        <Bar className="cursor-pointer" dataKey="inflow" fill="var(--color-inflow)" radius={4}
                             onClick={(data: any, i: number) => drillToTransactions(data, 'inflow')}/>
                        <Bar className="cursor-pointer" dataKey="outflow" fill="var(--color-outflow)" radius={4}
                             onClick={(data: any, i: number) => drillToTransactions(data, 'outflow')}/>
                    </ComposedChart>

                </ChartContainer>
            </div>


    );
}

