import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/24/outline'

export default function Popup({open, onClose, onConfirm, title, children}) {

    return (
        <Dialog open={open} onClose={onClose} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="flex px-8 py-4">
                            <div className="w-16 ">
                                <div className="flex size-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon aria-hidden="true" className="size-6 text-green-600"/>
                                </div>
                            </div>

                            <div className="flex-1 mt-3">
                                <DialogTitle as="h3" className="text-lg font-semibold text-gray-900">
                                    {title}
                                </DialogTitle>
                                <div className="mt-2 pr-8">
                                    {children}
                                </div>
                            </div>
                        </div>
                        <div
                            className="p-4 mt-4 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 border-t-[1px] border-gray-200">
                            <button
                                type="button"
                                onClick={onConfirm}
                                className="inline-flex w-full justify-center rounded-md  bg-gradient-to-r from-pink-400 to-purple-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                            >
                                Enable
                            </button>


                            <button
                                type="button"
                                data-autofocus
                                onClick={onClose}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
