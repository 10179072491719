import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/react";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline";

export interface Option {
    value: string
    tailwindColor?: string | null
}

export interface Properties {
    selected: Option
    options: Array<Option>
    onUpdateFunc: (value: string) => void
}

export default function Dropdown({selected, options, onUpdateFunc}: Properties) {
    return (
        <div>

            <Listbox value={selected.value} onChange={(e) => {
                onUpdateFunc(e)
            }}>
                <ListboxButton
                    className="relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 inline-flex items-center gap-2">
                    {selected.tailwindColor &&
                        <div className={`bg-${selected.tailwindColor}-400 h-2 w-2 rounded-full`}></div>}
                    {selected.value}
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400"/>
                  </span>
                </ListboxButton>
                <ListboxOptions anchor="bottom"
                                className="px-2 py-3 absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm">
                    {options.map((option) => (
                        <ListboxOption key={option.value} value={option.value} className={({focus}) =>

                            (focus ? 'bg-blue-600 text-white ' : '') +
                                (!focus ? 'text-gray-900' : '') +
                                'relative cursor-default select-none py-2 pl-3 pr-9 flex items-center gap-2 hover:text-white rounded-md'

                        }>
                            {option.tailwindColor &&
                                <div className={`bg-${selected.tailwindColor}-400 h-2 w-2 rounded-full`}></div>}
                            <span>{option.value}</span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </Listbox>
        </div>
    )
}


