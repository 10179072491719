import {useEffect, useState} from "react";
import {CustomerBankAccount} from "../../../../../api/CustomerBankAccounts/CustomerBankAccount.ts";
import {CustomerBankAccountRepository} from "../../../../../api/CustomerBankAccounts/CustomerBankAccountRepository.ts";
import {BackendApi} from "../../../../../api/api.ts";
import BankAccountSelectionSelector from "./BankAccountSelector.tsx";
import Shell from "../../../Shell.tsx";
import MonthlyInflowOutflowGraph from "./MonthlyInflowOutflowGraph.tsx";
import {User} from "../../../../../api/AccessControl/User.ts";
import ShinyButton from "./ShinyButton.tsx";
import Popup from "../../../../../components/ui/Popup.tsx";

function getDateStringFromTab(tabValue: number) {
    return new Date(tabValue, 0, 1).toISOString().split('T')[0]
}

export default function CashflowPage({currentUser}: { currentUser: User }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [boiGraph, setBoiGraph] = useState();
    const [selectedTabs, setSelectedTabs] = useState([new Date().getFullYear()]);
    const [bankAccounts, setBankAccounts] = useState<Array<CustomerBankAccount>>([]);
    const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>();
    const [isForecastingPopupOpen, setIsForecastingPopupOpen] = useState(false);

    useEffect(() => {
        onLoad()
    }, []);

    const api = new BackendApi(currentUser.getToken())


    async function fetchBoiGraphData(bankAccountId: string | null = null) {
        let body = {
            business_id: currentUser.businesses[0],
            start_date: getDateStringFromTab(Math.min(...selectedTabs)),
            end_date: getDateStringFromTab(Math.max(...selectedTabs) + 1),
        }

        let finalBody;

        if (bankAccountId) {
            finalBody = {...body, bank_account_id: bankAccountId}
        } else {
            finalBody = body
        }

        const res = await api.get(
            'business/stats/iob-graph-data',
            finalBody
        )
        res.json().then(data => setBoiGraph(data)).then(() => setIsLoading(false))
    }


    async function onLoad() {
        const bankAccountsResponse = await new CustomerBankAccountRepository(api)
            .getMyBankAccounts(currentUser.businesses[0], true)

        setBankAccounts(bankAccountsResponse)
        fetchBoiGraphData(selectedBankAccountId)

    }

    function handleDropdownChange(bankAccount: CustomerBankAccount) {
        setSelectedBankAccountId(bankAccount?.id)
        fetchBoiGraphData(bankAccount?.id)
    }

    const thisYear = new Date().getFullYear()
    const years = [thisYear - 2, thisYear - 1, thisYear];

    useEffect(() => {
        fetchBoiGraphData(selectedBankAccountId)
    }, [selectedTabs]);

    return (
        <>

            <Shell currentUser={currentUser} isLoading={isLoading}>
                <div className="w-full flex items-center mb-8 justify-between">
                    <div className="flex items-center gap-x-6">
                        <h1 className="weight-600 text-3xl">Cashflow</h1>


                    </div>
                    <BankAccountSelectionSelector defaultValue={'Alle'} bankAccounts={bankAccounts}
                                                  onUpdateFunc={handleDropdownChange}/>
                    <div className="">
                        <nav aria-label="Tabs" className="flex space-x-4">
                            {years.map((tab) => (
                                <button
                                    key={tab}
                                    onClick={
                                        () => {
                                            if (selectedTabs.includes(tab)) {
                                                if (selectedTabs.length === 1)
                                                    return
                                                setSelectedTabs((prev) => prev.filter((year) => year !== tab))
                                            } else {
                                                setSelectedTabs((prev) => [...prev, tab])
                                            }
                                        }
                                    }
                                    className={(selectedTabs.includes(tab) ? 'bg-gray-100 text-gray-900' : 'text-gray-500 hover:text-gray-700') + 'rounded-md px-3 py-2 text-sm font-medium rounded-md'}
                                >
                                    {tab}
                                </button>
                            ))}
                        </nav>
                    </div>

                </div>
                <MonthlyInflowOutflowGraph data={boiGraph} className={"h-[90vh]"}></MonthlyInflowOutflowGraph>

            </Shell>
        </>
    )
}
