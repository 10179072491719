interface Props {
    value: number,
    colorAid?: boolean
}

export function Money({value, colorAid}: Props) {

    if (colorAid) {
        if (value > 0) {
            return (
                <span className="text-green-600">
                    +{value.toLocaleString('nl-NL', {minimumFractionDigits: 2})}
                </span>
            )
        }

        return (
            <span className="text-red-600">
                {value.toLocaleString('nl-NL', {minimumFractionDigits: 2})}
            </span>
        )
    }

    return (
        <span className="">
            {value.toLocaleString('nl-NL', {minimumFractionDigits: 2})}
        </span>
    )
}
