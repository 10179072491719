import {User} from "../../../../../api/AccessControl/User.ts";
import Shell from "../../../Shell.tsx";
import {BackendApi} from "../../../../../api/api.ts";
import React, {useEffect, useState} from "react";
import {BusinessTable} from "./BusinessesTable.tsx";
import WideDrawer from "../../../../../components/ui/WideDrawer.tsx";
import {SecondaryButton} from "../../../../../components/SecondaryButton.tsx";
import DailyBalanceGraph from "../../../Entrepreneur/pages/BalancePage/DailyBalanceGraph.tsx";
import LoadingFrame from "../../../../../components/LoadingFrame.tsx";
import {Tab, Tabs} from "../../../../../components/ui/Tabs.tsx";
import {Field, Label, Switch} from "@headlessui/react";
import {confirming} from "../../../../../lib/confirming.ts";
import {BusinessSettingsService} from "../../../../../api/Business/BusinessSettingsService.ts";
import Dropdown from "../../../../../components/Dropdown.tsx";

export interface Business {
    id: string
    name: string
    legal_entity_type: string
    registration_number: string
    fte_amount: number
    industry: string
    description: string
    managed_by: string | null
    transaction_history_cutoff_date: {
        cutoff_date: string | null
    }
}

interface Props {
    currentUser: User
}

export default function BusinessesOverviewPage({currentUser}: Props) {
    const api = new BackendApi(currentUser.getToken())

    const businessSettingsService = new BusinessSettingsService(api)

    const [businesses, setBusinesses] = useState<Array<Business>>([])
    const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(null)

    async function onLoad() {
        const reviewTransactions = await api.get('admin/businesses', {})
        const response = await reviewTransactions.json()
        setBusinesses(response)
    }

    useEffect(() => {
        onLoad()
    }, []);

    return (
        <>
            <WideDrawer
                title={""}
                open={(!!selectedBusiness)}
                updateOpenState={(state) => {
                    if (state)
                        return
                    setSelectedBusiness(null)
                }}

            >
                <div className="flex items-center gap-x-3">
                    <SecondaryButton
                        title={'Previous'}
                        onClick={() => setSelectedBusiness((prev: Business) => {
                            const currentIndex = businesses.indexOf(prev)
                            if (currentIndex === 0)
                                return null
                            return businesses[currentIndex - 1]
                        })}
                    />
                    <span>
                        {businesses.indexOf(selectedBusiness) + 1} of {businesses.length}
                    </span>
                    <SecondaryButton
                        title={'Next'}
                        onClick={() => setSelectedBusiness((prev: Business) => {
                            const currentIndex = businesses.indexOf(prev)
                            if (currentIndex === businesses.length - 1)
                                return null
                            return businesses[currentIndex + 1]
                        })}
                    />
                    <Dropdown selected={({value: selectedBusiness?.id + ' ' + selectedBusiness?.name || ''})}
                              options={businesses.map((business) => ({value: business.id + ' ' + business.name}))}
                              onUpdateFunc={
                                  (value: string) => {
                                      setSelectedBusiness(businesses.find(business => business.id + ' ' + business.name === value))
                                  }
                              }/>
                </div>
                <div>
                    <h1 className="text-2xl font-bold leading-6 text-gray-900 mt-12 mb-6 ">{selectedBusiness?.name}</h1>
                </div>
                <Tabs>
                    <Tab label={'Bank Accounts'}>
                        <BusinessBalance
                            business={selectedBusiness}
                            api={api}
                        />
                    </Tab>
                    <Tab label={'Settings'}>
                        <BusinessSettings business={selectedBusiness} api={api}/>
                    </Tab>
                </Tabs>
                <div>

                </div>
            </WideDrawer>
            <Shell currentUser={currentUser} isLoading={false}>
                <h1 className="weight-600 text-3xl mb-6 ">Businesses</h1>
                <BusinessTable data={businesses} onOpenClick={(business) => setSelectedBusiness(business)}/>
            </Shell>
        </>
    )
}

function BusinessSettings({business, api}: { business: Business, api: BackendApi }) {
    const [settings, setSettings] = useState<Array<{
        setting_name: string,
        setting_value: boolean
    }>>([])

    const businessSettingsService = new BusinessSettingsService(api)

    async function load() {
        const response = await businessSettingsService.getAllSettings(business.id)

        const settingsProper = Object.entries(response).map((setting) => ({
            setting_name: setting[0],
            setting_value: setting[1]
        }))
        setSettings(settingsProper as Array<{
            setting_name: string,
            setting_value: boolean
        }>)
    }

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        setSettings([])
        load();
    }, [business]);

    return (
        <LoadingFrame isLoading={!settings.length}>
        {settings.map(({setting_name, setting_value}: {
            setting_name: string,
            setting_value: boolean
        }) => (
                <Field className="flex items-center justify-between max-w-lg mt-4">
                              <span className="flex grow flex-col">
                                <Label as="span" passive className="text-sm/6 font-medium text-gray-900">
                                  {setting_name}
                                </Label>
                              </span>
                    <Switch
                        checked={setting_value}
                        onChange={() => confirming('Are you sure you want to change this setting?', () => {
                            businessSettingsService.setSetting(business?.id, setting_name, !setting_value).then(() => {
                                load();
                            })
                        }, ()=>{})}
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
                    >
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                    </Switch>
                </Field>
            ))}
        </LoadingFrame>
    )

}

function BusinessBalance({business, api}: { business: Business, api: BackendApi }) {

    const [bankAccounts, setBankAccounts] = useState<Array<any>>([]);

    async function load() {
        const response = await api.get('admin/business/bank-accounts', {
            business_id: business.id
        })

        const bankAccountsResponse = await response.json()

        setBankAccounts(bankAccountsResponse)
    }

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        setBankAccounts([]);
        load();
    }, [business]);

    return (
        <>
            {bankAccounts?.length === 0 && <div className="text-center">No bank accounts found</div>}
            {bankAccounts?.map((bankAccount) => (
                <div>
                    <h2 className={"text-2xl mt-8 mb-4"}>{bankAccount.owner_name} - {bankAccount.iban}</h2>
                    <BalanceGraph business={business} api={api} bankAccountId={bankAccount.account_id}/>
                </div>
            ))}
        </>


    )
}


function BalanceGraph({business, api, bankAccountId}: { business: Business, api: BackendApi, bankAccountId: string }) {
    const [data, setData] = useState<any>();

    async function load() {
        const data = await api.get('admin/graph/business/get-daily-balance-with-forecast', {
            business_id: business.id,
            bank_account_id: bankAccountId,
            start_date: business.transaction_history_cutoff_date.cutoff_date || '2022-01-01T00:00:00',
            end_date: new Date().toISOString().split('T')[0],
        })

        const response = await data.json()
        setData(response)
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <LoadingFrame isLoading={!data}>
            <DailyBalanceGraph data={data || []} className={"h-[60vh]"}></DailyBalanceGraph>
        </LoadingFrame>
    )
}
