import {auth} from './firebaseConfig';

import './App.css'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {User} from "./api/AccessControl/User.ts";
import LoadingFrame from "./components/LoadingFrame.tsx";
import Intercom from '@intercom/messenger-js-sdk';
import {HotJar} from "./lib/hotjar.ts";
import {resolveRoutesForAuthState} from "./Routes.tsx";
import {processCurrentAuthState} from "./lib/TFICAuth.ts";


function onSuccessfulAuthenticationCallback(user: User) {
    Intercom({
        app_id: 'ah53zlwi',
        user_id: user.id,
        name: user.getFullName(),
        email: user.email,
    });

    HotJar.tagUserSession(user)
}

function App() {
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        auth.onAuthStateChanged(async (firebaseUser: any) => {
            const currentAuthState = await processCurrentAuthState(firebaseUser, onSuccessfulAuthenticationCallback)

            if (currentAuthState === null) {
                Intercom({
                    app_id: 'ah53zlwi'
                });
            }

            const routes = await resolveRoutesForAuthState(currentAuthState)
            setRoutes(routes)
        });
    }, [])

    if (!routes.length)
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='*' element={<LoadingFrame isLoading={true}><span>Loading...</span></LoadingFrame>}/>
                </Routes>
            </BrowserRouter>
        )

    return (
        <BrowserRouter>
            <Routes>
                {routes && routes.map(routeElement => (routeElement))}
            </Routes>
        </BrowserRouter>
    )


}

export default App
