import {useState} from "react";
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/react";
import {Label} from "recharts";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline/index";
import {CheckIcon} from "lucide-react";
import {CustomerBankAccount} from "../../../../../api/CustomerBankAccounts/CustomerBankAccount.ts";

interface Props {
    defaultValue: string,
    bankAccounts: Array<CustomerBankAccount>
    onUpdateFunc: (value: CustomerBankAccount | null) => void
}

export default function BankAccountSelectionSelector({defaultValue, bankAccounts, onUpdateFunc}: Props) {
    const [selectedId, setSelectedId] = useState<string>(defaultValue);

    function getAccount(id: string): CustomerBankAccount | null {
        return bankAccounts.find(bankAccount => bankAccount.id === id)
    }

    return (
        <Listbox value={selectedId || defaultValue} onChange={setSelectedId} as={'div'} className="w-56 focus:outline-none">
            <Label className="block text-sm/6 font-medium text-gray-900">Toegewezen aan</Label>
            <div className="relative mt-2">
                <ListboxButton
                    className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm/6">
                    <span className="flex items-center">
                        {selectedId !== defaultValue && <img alt="" src={getAccount(selectedId)?.logoUrl} className="size-5 shrink-0 rounded-full"/>}
                        {selectedId === defaultValue && <div className="size-5 shrink-0 rounded-full bg-gray-200"/>}
                        <span
                            className="ml-3 block truncate">{(getAccount(selectedId)?.ownerName || defaultValue)}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronUpDownIcon aria-hidden="true" className="size-5 text-gray-400"/>
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    <ListboxOption
                        value={defaultValue}
                        onClick={() => onUpdateFunc(null)}
                        className="group cursor-pointer relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-200 data-[focus]:text-white"
                    >
                        <div className="flex items-center">
                            <div className="size-5 shrink-0 rounded-full bg-gray-200"/>
                            <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                {defaultValue}
                            </span>
                        </div>

                        <span
                            className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon aria-hidden="true" className="size-5"/>
                        </span>
                    </ListboxOption>

                    {bankAccounts.map((bankAccount) => (
                        <ListboxOption
                            key={bankAccount.id}
                            value={bankAccount.id}
                            onClick={() => onUpdateFunc(bankAccount)}
                            className="group cursor-pointer relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-200"
                        >
                            <div className="flex items-center">
                                <img alt="" src={bankAccount.logoUrl} className="size-5 shrink-0 rounded-full"/>
                                <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                    {bankAccount.ownerName}
                                </span>
                            </div>

                            <span
                                className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon aria-hidden="true" className="size-5"/>
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    )
}
