import {BuildingOffice2Icon} from "@heroicons/react/24/outline/index";
import {useEffect, useState} from "react";
import {BackendApi} from "../../../api/api.ts";
import {PrimaryButton} from "../../../components/PrimaryButton.tsx";


function Growth({value}) {
    let color = 'text-out-color'

    if (value > -1) {
        color = 'text-in-color'
    }

    return (
        <span className={color + ' text-base pl-2'}>{value}%</span>
    )
}


function Row({data, currentUser}) {
    const api = new BackendApi(currentUser.getToken())

    const [baseStats, setBaseStats] = useState<any>();

    async function onLoad() {
        const ress = await api.get('business/stats/base-stats', {business_id: data.id})
        ress.json().then(data => setBaseStats(data)).then()
    }

    useEffect(() => {
        onLoad()
    }, []);

    if (!baseStats) return 'Loading...'

    return (
        <tr className="text-sm text-gray-900 hover:bg-gray-50 hover:cursor-pointer rounded-xl">
            <td className='py-2 px-4 leading-6 flex items-center'>
                <BuildingOffice2Icon className="h-12 w-12 bg-gray-100 p-2 rounded-full mr-4"/>
                <div className="leading-3">
                    <h2>{data.name}</h2>
                    <span
                        className="text-gray-500 text-xs">{data.fte_amount} {(data.fte_amount > 1 ? 'employees' : 'employee')}</span>
                </div>
            </td>

            <td className='py-2 px-4 text-gray-500'>
                Inflow
                <div>
                    € {baseStats?.inflow.current.toLocaleString()}
                    <Growth value={baseStats.inflow.change}/>
                </div>
            </td>
            <td className='py-2 px-4 text-gray-500'>
                Outflow
                <div>
                    € {baseStats?.outflow.current.toLocaleString()}
                    <Growth value={baseStats.outflow.change}/>
                </div>
            </td>
            <td className='py-2 px-4 text-gray-500'>
                Total Balance
                <div>
                    € {baseStats?.balance.current.toLocaleString()}
                    <Growth value={baseStats.balance.change}/>
                </div>
            </td>
            <td className='py-2 px-4 text-gray-500 flex flex-col items-end'>
                <PrimaryButton title={'Open'} onClick={()=>true} className="border border-gray-200 px-4 py-1.5"/>
            </td>
        </tr>
    )
}

export default function ClientsList({clients, currentUser}) {
    return (
        <table className="border-collapse w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 rounded-xl">
            <tr className="text-base text-gray-900">
                <td className="px-4 py-2 rounded-s-md">Client</td>
                <td className="px-4 py-2" colSpan={3}>Stats</td>
                <td className="px-4 py-2"></td>
            </tr>
            <tr className="h-2"></tr>
            </thead>
            <tbody>
            {clients.map((client) => (
                <Row data={client} currentUser={currentUser}/>
            ))}
            </tbody>
        </table>
    )
}
