import {XMarkIcon} from "@heroicons/react/24/outline";

export default function Banner({content, actionUrl}: { content: string, actionUrl: string }) {

    return <div
        className="pointer-events-auto flex items-center justify-between gap-x-6 bg-red-400 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <p className="text-sm/6 text-white">
            {content}
            <a href={actionUrl}>
                <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline size-0.5 fill-current">
                    <circle r={1} cx={1} cy={1}/>
                </svg>
                See details
            </a>
        </p>
    </div>
}
