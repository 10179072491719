import Shell from "../../Shell.tsx";
import {User} from "../../../../api/AccessControl/User.ts";
import Insights from "../Insights.tsx";

export default function InsightsPage({currentUser}: {currentUser: User}) {
    return (
        <Shell currentUser={currentUser}>
            <h1 className="weight-600 text-3xl mb-6 ">Insights</h1>
            <Insights></Insights>
        </Shell>
    )
}
