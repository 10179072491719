import {User} from "../../../../../api/AccessControl/User.ts";
import Shell from "../../../Shell.tsx";
import {TransactionReviewTable} from "./TransactionReivewTable.tsx";
import {BackendApi} from "../../../../../api/api.ts";
import {useEffect, useState} from "react";

export interface ReviewTransaction {
    id: string
    bank_account_id: string
    booking_date: string
    relation_name: string
    relation_iban: string
    amount: number
    flow: "inflow" | "outflow"
    currency: string
    description: string
    category: string
    rule_id: string,
    rule_name: string
}

interface Props {
    currentUser: User
}

export default function CategorizationReviewPage({currentUser}: Props) {
    const api = new BackendApi(currentUser.getToken())

    const [reviewTransactions, setReviewTransactions] = useState<Array<ReviewTransaction>>([])

    async function onLoad() {
        const reviewTransactions = await api.get('transactions/categorised-by-rules', {})
        const response = await reviewTransactions.json()
        setReviewTransactions(response)
    }

    useEffect(() => {
        onLoad()
    }, []);

    return (
        <>
            <Shell currentUser={currentUser} isLoading={false}>
                <h1 className="weight-600 text-3xl mb-6 ">Categorization Review</h1>
                <TransactionReviewTable data={reviewTransactions}/>
            </Shell>
        </>
    )
}
