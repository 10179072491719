import {BackendApi} from "../api.ts";
import {User} from "./User.ts";

export class HTTPCurrentUserRepository {
    constructor(readonly api: BackendApi) {
    }

    public async getCurrentUser(): Promise<User> {
        const rawResult = await this.api.get('access-control/who-am-i', {})
        const jsonResult = await rawResult.json();

        return User.fromApiResponse(jsonResult, this.api)
    }
}
