import {Money} from "../../../components/Money.tsx";

export default function Stats({data}) {

    if (!data) return;

    const stats = [
        {
            id: 2,
            prefix: '€ ',
            goodWhenLow: false,
            name: 'Inkomsten',
            isMoney: true,
            value: data.inflow.current,
            change: data.inflow.change
        },
        {
            id: 1,
            prefix: '€ ',
            goodWhenLow: true,
            name: 'Uitgaven',
            isMoney: true,
            value: data.outflow.current,
            change: data.outflow.change
        },
        {
            id: 3,
            prefix: '€ ',
            goodWhenLow: false,
            name: 'Totaal saldo',
            isMoney: true,
            value: data.balance.current,
            change: data.balance.change
        },
        {
            id: 4,
            prefix: '',
            goodWhenLow: false,
            name: 'Inkomstenbronnen',
            isMoney: false,
            value: data.unique_inflow_sources.current,
            change: data.unique_inflow_sources.change
        },
    ]

    return (
        <dl className="mt-2 grid grid-cols-1 gap-1 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col flex-start text-left  p-8 border-2 border-gray-50">
                    <dt className="text-lg  leading-6">
                        {stat.name} <br/>
                        <span className='text-sm text-gray-500'>
                        {`${new Date().toLocaleString('nl-NL', {month: 'short'})} vs. ${new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString('nl-NL', {month: 'short'})}`}
                        </span>
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                        {stat.prefix} {stat.isMoney && <Money value={stat.value}/>} {!stat.isMoney && stat.value}
                        <Growth value={stat.change} goodWhenLow={stat.goodWhenLow}/>
                    </dd>
                </div>
            ))}
        </dl>
    )
}

function Growth({value, goodWhenLow}) {
    let color = 'text-out-color'

    if (value > -1) {
        color = 'text-in-color'
    }

    if (goodWhenLow && value < -1) {
        color = 'text-in-color'
    }

    return (
        <span className={color + ' text-base pl-2'}>{value}%</span>
    )
}
