export interface FormTodoType {
    fieldName: string,
    message: string
}

interface Props {
    formTodos: Array<FormTodoType>,
    fieldName: string,
}

export function FormTodo({formTodos, fieldName}: Props) {


    return (
        <div>
            {formTodos.filter((todo) => todo.fieldName === fieldName).map((todo) => (
                    <p className="text-sm text-red-600 mt-2">{todo.message}</p>

                ))}
        </div>

    )


}
