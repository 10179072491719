import {useEffect, useState} from "react";
import {FormTodo, FormTodoType} from "../../components/form/FormTodo.tsx";

export interface StepTwoFormData {
    business_name: string,
    registration_number: string,
    legal_entity_type: string,
    fte_amount: number,
    industry: string,
    business_description: string,
}

interface Props {
    onUpdateFunc: (formData: StepTwoFormData) => void
    formTodos: Array<FormTodoType>
    defaultFormData?: Object
}

export function StepTwo({onUpdateFunc, formTodos, defaultFormData}: Props) {
    //@ts-ignore
    const [formData, setFormData] = useState<StepTwoFormData>(defaultFormData)

    useEffect(() => {
        onUpdateFunc(formData)
    }, [formData])
    return (
        <div>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Tell us about your
                business</p>
            <p className="mt-4 text-base block text-gray-600 mb-12">
                We use this data to custom tailor the product for you
            </p>
            <form action="#" method="POST" className="space-y-6">

                <div className="flex w-full gap-6">
                    <div className="grow">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Business Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="business_name"
                                name="business_name"
                                type="text"
                                required
                                defaultValue={formData.business_name}
                                onChange={(e) => setFormData({...formData, business_name: e.target.value})}
                                autoComplete="email"
                                className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <FormTodo fieldName={'business_name'} formTodos={formTodos}/>

                        </div>
                    </div>
                    <div className="grow">
                        <label htmlFor="registration_number"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            Registration Number (KVK)
                        </label>
                        <div className="mt-2">
                            <input
                                id="registration_number"
                                name="registration_number"
                                type="number"
                                required
                                defaultValue={formData.registration_number}
                                onChange={(e) => setFormData({...formData, registration_number: e.target.value})}
                                className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            />
                            <FormTodo fieldName={'registration_number'} formTodos={formTodos}/>
                        </div>
                    </div>
                </div>

                <div>
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                        Legal Entity Type
                    </label>
                    <select
                        id="legal_entity_type"
                        name="legal_entity_type"
                        defaultValue={formData.legal_entity_type}
                        onChange={(e) => setFormData({...formData, legal_entity_type: e.target.value})}
                        className="mt-2 px-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-15 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
                    >
                        <option value="limited-liability">B.V. (Limited Liability)</option>
                        <option value="general-partnership">V.O.F (General Partnership)</option>
                        <option value="sole-proprietorship">Eenmaanzaak / ZZP (Sole Proprietorship, freelancer)</option>
                    </select>
                    <FormTodo fieldName={'legal_entity_type'} formTodos={formTodos}/>

                </div>

                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Full Time Employees amount (including you)
                    </label>
                    <div className="mt-2">
                        <input
                            id="fte_amount"
                            name="fte_amount"
                            min={1}
                            type="number"
                            defaultValue={formData.fte_amount}
                            onChange={(e) => setFormData({...formData, fte_amount: parseInt(e.target.value)})}
                            required
                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        />
                        <FormTodo fieldName={'fte_amount'} formTodos={formTodos}/>

                    </div>
                </div>

                <div className="grow">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Industry
                    </label>
                    <div className="mt-2">
                        <input
                            id="industry"
                            name="industry"
                            type="text"
                            required
                            defaultValue={formData.industry}
                            onChange={(e) => setFormData({...formData, industry: e.target.value})}
                            autoComplete="email"
                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        />
                        <FormTodo fieldName={'industry'} formTodos={formTodos}/>

                    </div>
                </div>

                <div>
                    <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                        Tell us about your business in a few words. Who are your customers? What do you sell?
                    </label>
                    <div className="mt-2">
                <textarea
                    id="business_description"
                    name="business_description"
                    rows={4}
                    onChange={(e) => setFormData({...formData, business_description: e.target.value})}
                    className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    defaultValue={formData.business_description}
                />
                        <FormTodo fieldName={'business_description'} formTodos={formTodos}/>

                    </div>
                </div>
            </form>
        </div>
    )
}
