import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {EllipsisHorizontalIcon, ExclamationCircleIcon} from "@heroicons/react/24/outline/index";

function CategoryPillDropDown({onWrongCategoryClick}: { onWrongCategoryClick?: () => void }) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <MenuButton><EllipsisHorizontalIcon className="h-5 w-5 hover:text-gray-600"/></MenuButton>
            <MenuItems
                transition
                className="absolute shadow right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1 hover:text-gray-600 px-2">

                    <MenuItem as="div" className="relative flex items-center gap-2" onClick={()=>onWrongCategoryClick()}>
                        <ExclamationCircleIcon className="h-5 w-5 text-gray-400"/>
                        <a
                            href="#"
                            className="block py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            Mark as wrong
                        </a>
                    </MenuItem>

                </div>
            </MenuItems>
        </Menu>
    )
}

interface CategoryPillProps {
    category: string,
    flow: string,
    onWrongCategoryClick?: () => void
}

export default function CategoryPill({category, flow, onWrongCategoryClick}: CategoryPillProps) {
    return (
        <div className="group flex items-center gap-x-2 ">
            <span
                className="inline-flex items-center gap-x-1.5   font-medium text-gray-900 rounded-md px-2 py-1 ring-1 ring-inset ring-gray-200 w-auto">
                <svg viewBox="0 0 6 6" aria-hidden="true"
                     className={(flow === 'inflow') ? 'fill-green-500 h-1.5 w-1.5' : 'fill-red-500 h-1.5 w-1.5'}>
                    <circle r={3} cx={3} cy={3}/>
                </svg>
                {category}
            </span>
            <div className={'text-transparent group-hover:text-gray-400 cursor-pointer'}>
                {onWrongCategoryClick && <CategoryPillDropDown onWrongCategoryClick={onWrongCategoryClick}/>}
            </div>
        </div>

    )
}
