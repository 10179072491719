import {BackendApi} from "../api.ts";
import {BaseTransaction} from "./BaseTransaction.ts";

export class ApiResult<T> {
    constructor(readonly currentPage: number, readonly totalPages: number, readonly totalResults: number, readonly results: T) {
    }
}

export class BaseTransactionsRepository {
    private api: BackendApi;

    constructor(api: BackendApi) {
        this.api = api
    }



    public async getCustomerBankAccountTransactions(account_id: string, page: number = 0, onlyUncategorized: boolean = false, pageSize: number = 100): Promise<ApiResult<BaseTransaction[]>> {
        const rawResult = await this.api.get('catestial/base-transactions', {
            bank_account_id: account_id,
            page: page - 1,
            page_size: pageSize,
            onlyUncategorized: onlyUncategorized
        })
        const jsonResult = await rawResult.json();

        return this.parseApiResponseToBaseTransactions(jsonResult)
    }

    private parseApiResponseToBaseTransactions(jsonResult: Object): ApiResult<BaseTransaction[]> {
        const result = []
        for (const rawItem of jsonResult['result']) {
            result.push(BaseTransaction.fromApiResponse(rawItem))
        }

        const totalPages = Math.ceil(parseInt(jsonResult['total']) / parseInt(jsonResult['page_size']))

        return new ApiResult<BaseTransaction[]>(parseInt(jsonResult['page']) + 1, totalPages, parseInt(jsonResult['total']), result)
    }

    public async getBusinessBankTransactions(
        businessId: string,
        page: number = 0,
        pageSize: number = 10000000,
        orderBy: string | null = null,
        orderDescending: boolean | null = null
    ): Promise<ApiResult<BaseTransaction[]>> {

        let paginationState = {};

        if (orderBy) {

            const sortingFieldConversion = {
                'transactionId': 'transaction_id',
                'relationName': 'relation_name',
                'relationIBAN': 'relation_iban',
                'amount': 'transaction_value',
                'value': 'transaction_value',
                'flow': 'flow',
                'category': 'category',
                'description': 'description',
                'bookingDate': 'booking_date',
            }

            paginationState = {
                order_by: sortingFieldConversion[orderBy] || orderBy,
                order_descending: orderDescending
            }
        }

        const rawResult = await this.api.get('business/bank-transactions',
            {
                business_id: businessId,
                page: Math.max(page - 1, 0),
                page_size: pageSize,
                ...paginationState
            }
        )
        const jsonResult = await rawResult.json();

        return this.parseApiResponseToBaseTransactions(jsonResult)
    }

    public async getCustomerBankAccountTransaction(transactionId: string): Promise<ApiResult<BaseTransaction>> {
        const rawResult = await this.api.get('catestial/base-transactions/' + transactionId, {})
        const jsonResult = await rawResult.json();

        return new ApiResult<BaseTransaction>(0, 1, 1, BaseTransaction.fromApiResponse(jsonResult))
    }


    public async save(transaction: BaseTransaction): Promise<void> {
        this.api.post('catestial/categorize-transactions', {
            transaction_ids: [
                transaction.id
            ],
            category: transaction.category
        })
    }

    public async bulkCategorize(transactionIds: Array<string>, category: string): Promise<any> {
        return this.api.post('catestial/bulk/categorize-transactions', {
            transaction_ids: transactionIds,
            category: category
        })
    }


    public async categorizeBatch(transactions: Array<BaseTransaction>, category: string): Promise<any> {
        return this.api.post('catestial/categorize-transactions', {
            transaction_ids: transactions.map(transaction => transaction.id),
            category: category
        })
    }

}
