import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import * as React from "react";

interface ButtonSpec {
    type: "primary" | "secondary",
    title: string,
    onClick: () => void
    disabled?: boolean
    icon?: any;
    dropdownItems: Array<{
        title: string,
        onClick: () => void,
        icon?: any;
    }>
}

export default function ButtonWithDropDown(props: ButtonSpec) {
    let styles = "bg-white text-gray-900 ring-gray-300 "
    if (props.type === 'primary')
        styles = " bg-primary px-2 py-1 text-white transition hover:transition ring-white"

    return (
        <div className="inline-flex rounded-md ">
            <button
                type="button"
                className={"relative flex items-center leading-3 rounded-l-md  px-3 py-2 text-sm font-semibold  ring-1 ring-inset hover:opacity-70 focus:z-10" + styles}
                onClick={props.onClick}
                disabled={props.disabled}
            >
                {props.icon && <props.icon
                    className='h-4 w-4 mr-1.5'
                />} <span className={'pt-0.5'}>{props.title}</span>
            </button>
            <Menu as="div" className="relative -ml-px block">
                <MenuButton
                    className={"relative inline-flex items-center rounded-r-md  px-2 py-2 text-gray-400 ring-1 ring-inset  hover:opacity-70 focus:z-10" + styles}>
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon aria-hidden="true" className="size-5"/>
                </MenuButton>
                <MenuItems
                    transition
                    className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <div className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                        {props.dropdownItems.map((item) => (
                            <MenuItem key={item.title} as="div" className="px-2 w-full cursor-pointer flex items-center" onClick={item.onClick}>
                                {props.icon && <props.icon
                                    className='h-4 w-4 mr-1.5'
                                />} <span className={'pt-0.5'}>{item.title}</span>
                            </MenuItem>
                        ))}
                    </div>
                </MenuItems>
            </Menu>
        </div>
    )
}
