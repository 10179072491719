import {createTable, RenderingPresetType, TableHeaderSpec} from "../../../../../components/table/CreateTable.tsx";
import {SecondaryButton} from "../../../../../components/SecondaryButton.tsx";
import {CategorizationRule} from "../CategorizationRulesPage/RulesTable.tsx";
import {ReviewTransaction} from "./CategorizationReviewPage.tsx";
import {Transaction} from "../../../Entrepreneur/TransactionsTable.tsx";
import CategoryPill from "../../../../../components/pocketCFO/CategoryPill.tsx";
import React from "react";
import {BaseTransaction} from "../../../../../api/BaseTransactions/BaseTransaction.ts";
import {ClipboardDocumentListIcon, InformationCircleIcon} from "@heroicons/react/24/outline/index";

interface Props {
    data: Array<ReviewTransaction>,
}

export function TransactionReviewTable({data}: Props) {
    const tableSpec: TableHeaderSpec<ReviewTransaction> = {

        columns: [
            {
                name: '',
                field: '',
                customRender: (row: ReviewTransaction) => (
                    <span
                        onClick={() => window.open("/base-transactions/" + row.id, "Transaction")}><InformationCircleIcon
                        className="w-6 h-6 cursor-pointer"/></span>
                )
            },
            {
                name: 'Relation IBAN',
                field: 'relation_iban',
                customRender: (row: ReviewTransaction) => (
                    <div className="text-sm text-gray-500">{row.relation_name}<br/>{row.relation_iban}</div>
                )
            },
            {
                name: 'Description',
                field: 'description'
            },
            {
                name: 'Booking Date',
                field: 'booking_date'
            },
            {
                name: 'Amount',
                field: 'transaction_value',
                renderingPreset: RenderingPresetType.MoneyColorAid
            },
            {
                name: 'Category',
                field: 'category',
                customRender: (row: ReviewTransaction) => (
                    <span
                        className="inline-flex whitespace-nowrap items-center gap-x-1.5 rounded-md px-2 py-1  font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                            <svg viewBox="0 0 6 6" aria-hidden="true"
                                 className={(row.flow === 'inflow') ? 'fill-green-500 h-1.5 w-1.5' : 'fill-red-500 h-1.5 w-1.5'}>
                                <circle r={3} cx={3} cy={3}/>
                            </svg>
                        {row.category}
                    </span>
                )
            },
            {
                name: 'Rule',
                field: 'rule_id',
                customRender: (row: ReviewTransaction) => (
                    <a
                        href={'/categorization-rules?edit_rule_id=' + row.rule_id}
                        className="inline-flex whitespace-nowrap items-center gap-x-1.5 rounded-md px-2 py-1  font-medium text-gray-900 ring-1 ring-inset ring-gray-200 cursor-pointer">
                        <ClipboardDocumentListIcon className="h-4 w-4 text-gray-400"/>
                        {row.rule_name}
                    </a>
                )
            },

        ]
    }

    const TableComponent = createTable<ReviewTransaction>(data, tableSpec)

    return (
        <>
            {TableComponent()}
        </>
    )
}
