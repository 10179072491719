import {
    createTable,
    PaginationState,
    RenderingPresetType,
    SortingState,
    TableHeaderSpec
} from "../../../../../components/table/CreateTable.tsx";
import {Transaction} from "../../TransactionsTable.tsx";
import CategoryPill from "../../../../../components/pocketCFO/CategoryPill.tsx";
import React from "react";
import {CustomerBankAccount} from "../../../../../api/CustomerBankAccounts/CustomerBankAccount.ts";

interface Props {
    transactions: Array<Transaction>,
    bankAccounts: Array<CustomerBankAccount>,
    onWrongCategoryClick?: (transaction: Transaction) => void,
    paginationState: [
        paginationState: PaginationState<Transaction>,
        setPaginationState: (paginationState: any) => void
    ],
    sortingState?: [
        sortingState: SortingState,
        setSortingState: (sortingState: any) => void
    ],
    inMemoryPaginationUpdater?: (currentPage: number, pageSize: number) => void,
    onQueryUpdate: (pagination: PaginationState<Transaction> | null, sorting: SortingState | null) => void
}

export function TransactionsTable({transactions, bankAccounts, onWrongCategoryClick, paginationState, sortingState, inMemoryPaginationUpdater, onQueryUpdate}: Props) {
    const tableSpec: TableHeaderSpec<Transaction> = {
        title: `${transactions.length} Transactions`,
        columns: [
            {
                name: 'Relatie',
                field: 'relationName',

                customRender: (row: Transaction) => (
                    <div className="text-sm text-gray-500">{row.relationName} <br/> {row.relationIBAN}</div>
                )
            },
            {
                name: 'Bedrag',
                field: 'value',
                style: {
                    widthMultiplier: 0.5
                },
                renderingPreset: RenderingPresetType.MoneyColorAid
            },
            {
                name: 'Omschrijving',
                field: 'description'
            },
            {
                name: 'Datum',
                field: 'bookingDate',
                style: {
                    nowrap: true,
                    widthMultiplier: 0.75
                },
            },
            {
                name: 'Categorie',
                field: 'category',
                style: {
                    nowrap: true,
                    widthMultiplier: 1.5
                },
                customRender: (row: Transaction) => (
                    <CategoryPill category={row.category} flow={row.flow}
                                  onWrongCategoryClick={() => onWrongCategoryClick(row)}/>
                )
            },
            {
                name: 'Bank',
                field: 'bankAccountId',
                customRender: (row: Transaction) => {

                    const bankAccount = bankAccounts.find(bankAccount => bankAccount.id === row.bankAccountId)

                    return (
                        <div className="flex items-center gap-x-2 whitespace-nowrap">
                            <img src={bankAccount.logoUrl}
                                 alt="Banklogo" className="h-6 rounded-xl mix-blend-multiply"/>
                            <span>{bankAccount.ownerName}</span>
                        </div>
                    )
                }
            },
        ],
        pagination: {
            inMemory: true,
            paginationState: paginationState,
            totalPages: Math.ceil(transactions.length / 50),
            defaultPageSize: 50,
        },

        sorting: {
            sortingState: sortingState,
        },
        onQueryUpdate: onQueryUpdate,
    }


        const TableComponent = createTable<Transaction>(transactions, tableSpec)

    return (
        <>
            {TableComponent()}
        </>
    )
}
