const activity = [
    {
        id: 1,
        content: 'Voor Ondernemerscollectief 1 in Amsterdam verwachten we een kaswinst van EUR 8.421 voor het kalenderjaar 2024. We raden aan om EUR 2.000 contant opzij te zetten voor inkomstenbelasting.',
        dateTime: '2023-01-23T15:56',
        buttons: () => (
            <span className="isolate inline-flex rounded-md">
              <button
                  type="button"
                  className=" px-4 relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              >
                  ✅
              </button>
              <button
                  type="button"
                  className="px-4 relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              >
                  ❌
              </button>
            </span>
        )
    },
    {
        id: 2,
        content: 'Voor Ondernemerscollectief 4 in Rotterdam verwachten we dat je op 16 december 2024 zonder geld komt te zitten als je huidige uitgaven ongewijzigd blijven. Probeer de late betaling van openstaande facturen van klant Blorg te innen.',
        dateTime: '2023-01-23T15:56',
        buttons: () => (
            <button
                type="button"
                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
                Ga naar cashflow
            </button>
        )
    },
    {
        id: 3,
        content: 'Voor Ondernemerscollectief 6 in Den Haag zien we dat de uitgaven aan elektriciteit 30% hoger zijn vergeleken met een omzetgroei van slechts 10%, in de maand augustus 2024. Het kan de moeite waard zijn om te onderzoeken waarom dat zo is.',
        dateTime: '2023-01-23T15:56',
        buttons: () => (
            <button
                type="button"
                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
                Ga naar transacties in augustus
            </button>
        )
    },
]

function getInsights() {
    if (false) return activity

    return [
        {
            id: 1,
            content: "Nog geen inzichten voor je bedrijf. We zijn hard bezig met het analyseren van de gegevens die je met ons hebt gedeeld. Laat ons weten welke specifieke inzichten je graag zou willen via de knop hieronder",
            dateTime: '2023-01-23T15:56',
            buttons: () => (
                <span className="isolate inline-flex rounded-md">
              <a
                  href={'mailto:hello@pocketcfo.io?subject=add_insight'}
                  className=" px-4 relative inline-flex items-center rounded-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              >
                  ➕ Vraag inzicht aan
              </a>
            </span>
            )
        },
    ]
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function QuinnAvatar({className}) {
    return <svg width="188" height="189" viewBox="0 0 188 189" fill="none"
                xmlns="http://www.w3.org/2000/svg" className={className}>
        <mask id="mask0_179_474" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0"
              width="188" height="189">
            <circle cx="93.7852" cy="94.4653" r="93.7759" fill="#AEFFA7"/>
        </mask>
        <g mask="url(#mask0_179_474)">
            <circle cx="93.7852" cy="94.4653" r="93.7759" fill="#AEFFA7"/>
            <path
                d="M149.953 171.663C149.953 202.686 151.976 227.835 93.7812 227.835C37.6094 227.835 37.6094 202.686 37.6094 171.663C37.6094 140.64 62.7584 115.491 93.7812 115.491C124.804 115.491 149.953 140.64 149.953 171.663Z"
                fill="#7AB7FF"/>
            <path
                d="M117.148 122.585C117.148 136.282 106.263 142.033 92.8359 142.033C79.4088 142.033 68.5239 136.282 68.5239 122.585C68.5239 108.888 79.4088 97.7852 92.8359 97.7852C106.263 97.7852 117.148 108.888 117.148 122.585Z"
                fill="#EFC9A4"/>
            <path
                d="M117.148 122.585C117.148 136.282 106.263 142.033 92.8359 142.033C79.4088 142.033 68.5239 136.282 68.5239 122.585C68.5239 108.888 79.4088 97.7852 92.8359 97.7852C106.263 97.7852 117.148 108.888 117.148 122.585Z"
                fill="#EFC9A4"/>
            <rect x="68.5239" y="66.6187" width="48.6239" height="56.3891" fill="#EFC9A4"/>
            <circle cx="88.4421" cy="56.1725" r="34.4245" fill="#FAD5B2"/>
            <circle cx="88.4421" cy="91.7778" r="34.4245" fill="#FAD5B2"/>
            <rect x="54.0176" y="53.0822" width="68.8491" height="37.5149" fill="#FAD5B2"/>
            <rect x="57.5435" y="62.399" width="15.208" height="3.73964" rx="1.86982"
                  fill="#783300"/>
            <rect x="74.2437" y="83.2005" width="12.9432" height="5.36358" rx="2.68179"
                  fill="#FF8B7B"/>
            <rect x="88.4419" y="62.399" width="15.208" height="3.73964" rx="1.86982"
                  fill="#783300"/>
            <circle cx="67.4592" cy="71.8396" r="3.95384" fill="#003C15"/>
            <circle cx="93.782" cy="71.8396" r="3.95384" fill="#003C15"/>
            <path
                d="M72.3122 33.8073C60.6328 38.1933 56.0074 40.7837 54.2167 54.0627C46.906 42.1265 56.8482 28.4337 68.8386 20.9398C72.4195 18.7018 72.2703 17.8065 74.2098 14.6733C83.5002 7.59495 103.453 20.9398 103.453 20.9398L110.466 18.7018C110.466 18.7018 107.654 22.9302 107.93 23.4763C108.205 24.0224 115.24 23.4763 115.24 23.4763C115.24 23.4763 114.709 24.1521 111.809 25.4159C108.909 26.6796 131.13 38.0312 126.132 55.8532C125.298 58.8266 121.283 56.2781 118.225 55.8532C103.88 53.8603 106.936 35.9182 92.8947 32.3687C85.5996 30.5245 85.442 40.969 72.3122 33.8073Z"
                fill="#783300"/>
            <circle cx="120.388" cy="66.1388" r="11.0236" fill="#FAD5B2"/>
            <circle cx="120.388" cy="77.5405" r="11.0236" fill="#FAD5B2"/>
            <rect x="109.365" y="65.1492" width="22.0471" height="12.0132" fill="#FAD5B2"/>
            <path
                d="M96.0454 100.56C96.0454 104.095 89.7717 106.96 82.0327 106.96C74.2937 106.96 68.02 104.095 68.02 100.56C68.02 97.025 74.2937 100.56 82.0327 100.56C89.7717 100.56 96.0454 97.025 96.0454 100.56Z"
                fill="#FF8B7B"/>
        </g>
    </svg>;
}

interface Props {
    className?: string,
    minimum?: boolean
}

export default function Insights({className, minimum}: Props) {

    return (
        <>
            <div
                className={"lg:col-start-3 border-2 border-gray-50 px-8 pb-8 rounded-md overflow-y-auto " + className}>
                <ul role="list" className=" space-y-6">

                    {!!getInsights().length && getInsights().map((activityItem, activityItemIdx) => (
                        <li key={activityItem.id} className="relative flex gap-x-4 pt-8">
                            <div
                                className={classNames(
                                    (activityItemIdx === activity.length - 1 || activity.length === 0) ? 'h-12' : '-bottom-6',
                                    'absolute left-0 top-0 flex w-6 justify-center',
                                    (activityItemIdx === 0) ? '-top-12' : ''
                                )}
                            >
                                <div className="w-px bg-gray-200"/>
                            </div>

                            <QuinnAvatar className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"/>

                            <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                                <p className="text-base leading-6 text-gray-900 pb-4">{activityItem.content}</p>
                                <div className="flex justify-between gap-x-4">
                                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                                        { minimum || (activityItem.buttons === null) ? <span
                                                className="font-medium text-gray-500">Quinn</span> : activityItem.buttons() }
                                    </div>
                                </div>
                            </div>

                        </li>
                    ))}
                </ul>

            </div>
        </>
    )
}
