interface BankAccountData {
    account_id: string;
    iban: string;
    currency: string;
    owner_name: string;
    institution_name: string;
    balance: number;
    connected_on: string;
    refreshed_on: string;
    last_transaction_full_sync_on: string;
}

export class CustomerBankAccount {
    public id: string;
    public iban: string;
    public currency: string;
    public ownerName: string;
    public institutionName: string;
    public balance: number;
    public connectedOn: string;
    public refreshedOn: string;
    public lastTransactionFullSyncOn: string;

    constructor(
        id: string,
        iban: string,
        currency: string,
        ownerName: string,
        institutionName: string,
        balance: number,
        connectedOn: string,
        refreshedOn: string,
        lastTransactionFullSyncOn: string,
        public logoUrl: string = ""
    ) {
        this.id = id;
        this.iban = iban;
        this.currency = currency;
        this.ownerName = ownerName;
        this.institutionName = institutionName;
        this.balance = balance;
        this.connectedOn = connectedOn;
        this.refreshedOn = refreshedOn;
        this.lastTransactionFullSyncOn = lastTransactionFullSyncOn;
    }

    public withLogoUrl(logoUrl: string): CustomerBankAccount {
        return new CustomerBankAccount(
            this.id,
            this.iban,
            this.currency,
            this.ownerName,
            this.institutionName,
            this.balance,
            this.connectedOn,
            this.refreshedOn,
            this.lastTransactionFullSyncOn,
            logoUrl
        )
    }


    public static fromApiResponse(data: BankAccountData): CustomerBankAccount {
        return new CustomerBankAccount(
            data.account_id,
            data.iban,
            data.currency,
            data.owner_name,
            data.institution_name,
            data.balance,
            data.connected_on,
            data.refreshed_on,
            data.last_transaction_full_sync_on
        );
    }
}
