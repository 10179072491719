
interface Props {
    isLoading: boolean
    children: any
}

export default function LoadingFrame(props: Props) {
    if (props.isLoading)
        return (
            <div className={"absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center h-[90vh]"}><img
                src="/loader-slow.gif" className={"h-16 w-16"} alt=""/></div>
        )

    return (
        <>
            {props.children}
        </>
    )
}
