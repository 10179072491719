import {Money} from "../Money.tsx";
import {SecondaryButton} from "../SecondaryButton.tsx";
import {
    ArrowUpIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from "@heroicons/react/24/outline/index";
import {useEffect, useState} from "react";

interface TableCellSpec<TableRowDataT> {
    row: TableRowDataT
    columnSpec: TableColumnSpec<TableRowDataT>
}

function TableCell<TableRowDataT>({row, columnSpec}: TableCellSpec<TableRowDataT>) {
    if (columnSpec.customRender)
        return columnSpec.customRender(row)

    if (columnSpec.renderingPreset === RenderingPresetType.Money)
        return (
            <>
                <Money value={row[columnSpec.field] / 100}/>
            </>
        )

    if (columnSpec.renderingPreset === RenderingPresetType.MoneyColorAid)
        return (
            <>
                <Money value={row[columnSpec.field] / 100} colorAid={true}/>
            </>
        )

    let styles = ""

    if (columnSpec.style?.nowrap)
        styles += " whitespace-nowrap"

    return (
        <span className={styles}>
            {row[columnSpec.field]}
        </span>
    )
}

interface TableRowProps<TableRowDataT> {
    columnSpecs: Array<TableColumnSpec<TableRowDataT>>
    row: TableRowDataT,
}

function TableRow<TableRowDataT extends IdentifiableRecord>({columnSpecs, row}: TableRowProps<TableRowDataT>) {
    return (
        <>
            <tr key={row?.id || self.crypto.randomUUID()} data-item-id={row?.id} className="py-2 bg-gray-50 w-full">
                {columnSpecs.map((columnSpec: TableColumnSpec<TableRowDataT>) => (
                    <td className={'py-2 px-4' + ` w-[${getColumnSize(columnSpecs, columnSpec) + '%'}]`} style={{width: getColumnSize(columnSpecs, columnSpec) + '%'}}>
                        <TableCell<TableRowDataT> row={row} columnSpec={columnSpec}/>
                    </td>
                ))}
            </tr>
            <tr key={(row?.id || self.crypto.randomUUID()) + 'spacer'} className="h-2"></tr>
        </>
    )
}

export enum RenderingPresetType {
    Money,
    MoneyColorAid
}


interface TableColumnSpec<TableDataT> {
    name: string,
    field: string,
    style?: {
        nowrap?: boolean
        widthMultiplier?: number
    }
    customRender?: (row: TableDataT) => any
    renderingPreset?: RenderingPresetType
}

export interface PaginationState<TableDataT> {
    currentPage: number,
    pageSize: number
    selectedItemsInMemory?: Array<TableDataT>
}

export interface SortingState {
    orderBy: string,
    orderDescending: boolean
}

export interface TableHeaderSpec<TableDataT> {
    title?: string
    columns: Array<TableColumnSpec<TableDataT>>
    pagination?: {
        inMemory?: boolean,
        paginationState: [
            paginationState: PaginationState<TableDataT>,
            setPaginationState: (paginationState: any) => void
        ]
        defaultPageSize: number,
        totalPages: number,
    }
    sorting?: {
        sortingState: [
            sortingState: SortingState,
            setSortingState: (sortingState: any) => void
        ]
    }
    onQueryUpdate?: (pagination: PaginationState<TableDataT> | null, sorting: SortingState | null) => void
}

interface IdentifiableRecord {
    id: string
}

interface SortingIndicatorProps {
    isActive: boolean
    isDescending: boolean
    onUpdate: (isAscending: boolean) => void
}

function SortingIndicator({isDescending, isActive, onUpdate}: SortingIndicatorProps) {
    let style = " cursor-pointer hover:bg-gray-100 rounded-full p-1 transition"

    if (isDescending) {
        style += " rotate-180 "
    }

    if (!isActive) {
        style += " opacity-0 hover:opacity-100"
    }

    return (
        <div className={style} onClick={() => {

            if (isActive)
                onUpdate(!isDescending)
            else
                onUpdate(false)
        }}>
            <ArrowUpIcon className={"size-4"}/>
        </div>
    )
}

function getColumnSize<TableDataT>(columns: Array<TableColumnSpec<TableDataT>>, column: TableColumnSpec<TableDataT>) {

    let fractions = 0

    for (const columnSpec of columns) {
        if (columnSpec?.style?.widthMultiplier)
            fractions += columnSpec.style.widthMultiplier
        else
            fractions += 1
    }

    const baseColumnWidth = 100 / fractions

    const currentColumnWidthMultiplier = column.style?.widthMultiplier || 1

    return baseColumnWidth * currentColumnWidthMultiplier
}

export function createTable<TableDataT extends IdentifiableRecord>(tableData: Array<TableDataT>, headers: TableHeaderSpec<TableDataT>) {


    function TableComponent() {
        const [paginationState, setPaginationState] = headers?.pagination?.paginationState || [null, null]

        const [sortingState, setSortingState] = headers?.sorting?.sortingState || [null, null]

        const isPaginationEnabled = 'pagination' in headers
        const isSortingEnabled = 'sorting' in headers

        if (headers?.pagination && paginationState === null) {
            setPaginationState({
                currentPage: 1
            })
        }


        function canIncrementPage() {
            return paginationState?.currentPage < headers.pagination?.totalPages
        }

        function canDecrementPage() {
            return paginationState?.currentPage > 1
        }

        function inMemoryPaginationUpdater(currentPage: number, pageSize: number) {
            const startOffset = (currentPage - 1) * pageSize
            const endOffset = currentPage * pageSize
            setPaginationState(
                (prev: PaginationState<TableDataT>) => {
                    return {
                        ...prev,
                        selectedItemsInMemory: tableData.slice(startOffset, endOffset),
                    }
                }
            )
        }

        function handlePageUpdate(change: number) {
            if (change === 0) return

            if (canIncrementPage() && change > 0) {
                const currentPage = paginationState.currentPage + change

                setPaginationState((prev) => {
                    console.log(prev)
                    const newState = {...prev, currentPage: currentPage}
                    if (!headers.pagination.inMemory)
                        headers.onQueryUpdate(newState, sortingState)
                    return newState
                })
                inMemoryPaginationUpdater(currentPage, paginationState.pageSize)
            }

            if (canDecrementPage() && change < 0) {
                const currentPage = paginationState.currentPage + change

                setPaginationState((prev) => {
                    const newState = {...prev, currentPage: prev.currentPage + change}

                    if (!headers.pagination.inMemory)
                        headers.onQueryUpdate(newState, sortingState)

                    return newState
                })
                inMemoryPaginationUpdater(currentPage, paginationState.pageSize)
            }
        }

        function handleSorting(field: string, orderDescending: boolean) {
            setSortingState((prev) => {
                const newState = {...prev, orderBy: field, orderDescending: orderDescending}
                headers.onQueryUpdate(paginationState, newState)
                return newState
            })

        }


        useEffect(() => {
            if (headers?.pagination?.inMemory) {
                setPaginationState({
                    currentPage: 1,
                    pageSize: headers.pagination.defaultPageSize
                })
            }

            if (headers?.pagination?.inMemory) {
                inMemoryPaginationUpdater(1, headers.pagination.defaultPageSize)
            }


        }, []);

        useEffect(() => {
            if (!(headers?.pagination?.inMemory && paginationState?.currentPage))
                return
            inMemoryPaginationUpdater(paginationState?.currentPage, headers?.pagination?.defaultPageSize)
        }, [tableData]);


        return (
            <>
                <div className="flex items-center justify-between gap-2 py-2 w-full">
                    <h2>{headers?.title}</h2>
                    {isPaginationEnabled &&
                        <div className="flex items-center gap-2 py-2">
                            <SecondaryButton
                                title={''}
                                icon={ChevronDoubleLeftIcon}
                                disabled={!canDecrementPage()}
                                onClick={() => {
                                    handlePageUpdate(-(paginationState.currentPage - 1))
                                }}
                            />
                            <SecondaryButton
                                title={''}
                                icon={ChevronLeftIcon}
                                disabled={!canDecrementPage()}
                                onClick={() => {
                                    handlePageUpdate(-1)
                                }}
                            />

                            <span>{paginationState?.currentPage} of {headers?.pagination?.totalPages}</span>
                            <SecondaryButton
                                title={''}
                                icon={ChevronRightIcon}
                                disabled={!canIncrementPage()}
                                onClick={() => {
                                    handlePageUpdate(+1)
                                }}
                            />
                            <SecondaryButton
                                title={''}
                                icon={ChevronDoubleRightIcon}
                                disabled={!canIncrementPage()}
                                onClick={() => {
                                    handlePageUpdate(headers.pagination.totalPages - paginationState.currentPage)
                                }}
                            />
                        </div>
                    }
                </div>

                <table
                    className="border-collapse w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="bg-gray-50 rounded-xl">
                    <tr className="text-base text-gray-900">
                        {headers.columns.map((header: TableColumnSpec<TableDataT>) => (
                            <th key={header.field} className="rounded-s-md px-4 py-2 text-nowrap">
                                <div className="flex items-center gap-x-2 group" style={{width: getColumnSize(headers.columns, header) + '%'}}>
                                    {header.name}
                                    {isSortingEnabled && header.field &&
                                        <SortingIndicator isActive={sortingState?.orderBy === header.field}
                                                          isDescending={sortingState?.orderDescending}
                                                          onUpdate={(isAscending) => {
                                                              handleSorting(header.field, isAscending)
                                                          }}/>
                                    }

                                </div>
                            </th>
                        ))}
                    </tr>
                    <tr className="h-2"></tr>
                    </thead>

                    <tbody>

                    {(headers?.pagination?.inMemory ? paginationState?.selectedItemsInMemory : tableData)?.map((row: TableDataT) => (
                        <>
                            <TableRow<TableDataT> columnSpecs={headers.columns} row={row}/>
                        </>
                    ))}
                    </tbody>
                </table>
                <div className="flex items-center justify-between gap-2 py-2 w-full">
                    <h2>{headers?.title}</h2>
                    {isPaginationEnabled &&
                        <div className="flex items-center gap-2 py-2">
                            <SecondaryButton
                                title={''}
                                icon={ChevronDoubleLeftIcon}
                                disabled={!canDecrementPage()}
                                onClick={() => {
                                    handlePageUpdate(-(paginationState.currentPage - 1))
                                }}
                            />
                            <SecondaryButton
                                title={''}
                                icon={ChevronLeftIcon}
                                disabled={!canDecrementPage()}
                                onClick={() => {
                                    handlePageUpdate(-1)
                                }}
                            />

                            <span>{paginationState?.currentPage} of {headers?.pagination?.totalPages}</span>
                            <SecondaryButton
                                title={''}
                                icon={ChevronRightIcon}
                                disabled={!canIncrementPage()}
                                onClick={() => {
                                    handlePageUpdate(+1)
                                }}
                            />
                            <SecondaryButton
                                title={''}
                                icon={ChevronDoubleRightIcon}
                                disabled={!canIncrementPage()}
                                onClick={() => {
                                    handlePageUpdate(headers.pagination.totalPages - paginationState.currentPage)
                                }}
                            />
                        </div>
                    }
                </div>
            </>
        )
    }

    return TableComponent
}
