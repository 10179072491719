interface Props {
    value: boolean,
    onUpdateFunc: (value: boolean) => void
    label: string
}

export default function Checkbox({value, onUpdateFunc, label}: Props) {
    return (
        <div className="relative flex items-start">
            <div className="flex h-6 items-center">
                <input
                    id="offers"
                    name="offers"
                    type="checkbox"
                    aria-describedby="offers-description"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={value}
                    onChange={(e) => onUpdateFunc(e.target.checked)}
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                <label htmlFor="offers" className="font-medium text-gray-900">
                    {label}
                </label>
            </div>
        </div>
    )
}
