import {BackendApi} from "../api.ts";


export class BusinessSettingsService {
    private api: BackendApi;

    constructor(api: BackendApi) {
        this.api = api
    }

    public async getAllSettings(businessId: string): Promise<Record<string, boolean>> {
        const response = await this.api.get('admin/business/settings', {
            business_id: businessId,
        })
        return await response.json()
    }

    public async setSetting(businessId: string, settingName: string, settingValue: boolean) {
        await this.api.post('admin/business/update-setting', {
            business_id: businessId,
            setting_name: settingName,
            setting_value: settingValue
        })
    }
}
