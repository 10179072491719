export const OUTFLOW_CATEGORIES = [
    "Purchases  of materials from suppliers",
    "Subcontracting",
    "Consumable supplies",
    "Packaging materials",
    "Net wages (Employees)",
    "Social charges (Employees)",
    "Management fees paid",
    "Private withdrawals",
    "Pensions",
    "Education",
    "Freelance - Staffing agents",
    "Other personnel costs",
    "Rent",
    "Maintenance building",
    "Electricity & Gas",
    "Water",
    "Cleaning",
    "Office supplies",
    "Shipping & Couriers",
    "Telco",
    "Printing",
    "Magazines & Paper subscriptions",
    "Advertising",
    "Publications",
    "Public relations",
    "Sponsoring",
    "Events",
    "Gifts",
    "Travel costs",
    "Hotel costs",
    "Software subscriptions",
    "Software development",
    "Software maintenance",
    "Hosting providers & cloud",
    "Accountant",
    "Bookkeeper",
    "Legal & Tax",
    "Consulting",
    "Operational lease cars",
    "Operational lease equipment",
    "Hardware",
    "Purchase cars",
    "Purchase equipment",
    "VAT paid",
    "Income tax paid",
    "Corporate tax paid",
    "Banking & related services",
    "Insurance premiums",
    "Dividends distributed",
    "Internal outflowing transfers",
    "Financial costs",
    "Repayment of current account",
    "Other expenses",
    "Acquisition of intangible fixed assets",
    "Acquisition of tangible fixed assets",
    "Acquisition of financial assets",
    "Loans repaid",
    "Leases repaid",
    "Interest paid",
]
export const INFLOW_CATEGORIES = [
    "Revenue from selling material goods",
    "Revenue from selling services",
    "Licenses",
    "Royalties",
    "Dividends",
    "Subsidies",
    "Rent received",
    "Management fees received",
    "Insurance damage pay-out",
    "Interest received",
    "Positive currency exchange differences",
    "Transfer of expenses",
    "Repayment of VAT credit",
    "Repayment of income tax credit",
    "Repayment of corporate tax credit",
    "Current account contributions",
    "Internal inflowing transfers",
    "Other revenue recurring",
    "Other revenue non-recurring",
    "Disposal of intangible fixed assets",
    "Disposal of tangible fixed assets",
    "Disposal of financial assets",
    "Equity taken",
    "Loans taken",
]
