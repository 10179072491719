import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyDrDOFpF0zq9hzeYcZB6x8y4z_4j1QS-aA',
    authDomain: 'tfc-operations.firebaseapp.com',
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
