export default class TFICCache<T> {
    private fetchFunction: (key: string) => Promise<T>;
    private cache: Record<string, T | null> = {};
    private pendingPromises: Record<string, Promise<T | null>> = {};

    withFetchFunction(fetchFunction: (key: string) => Promise<T>): void {
        this.fetchFunction = fetchFunction;
    }

    async get(key: string): Promise<T | null> {
        if (this.fetchFunction === undefined) {
            throw new Error("Fetch function not set");
        }

        if (key in this.cache) {
            return this.cache[key];
        }

        if (!this.pendingPromises[key]) {
            this.pendingPromises[key] = (async () => {
                try {
                    const data = await this.fetchFunction(key);
                    this.cache[key] = data;
                    return data;
                } catch (error) {
                    console.error(`Error fetching data for key "${key}":`, error);
                    this.cache[key] = null;
                    return null;
                } finally {
                    delete this.pendingPromises[key];
                }
            })();
        }

        return await this.pendingPromises[key];
    }
}
