// @ts-nocheck
import {BackendApi} from "../../api/api.ts";
import Shell from "./Shell.tsx";
import {User} from "../../api/AccessControl/User.ts";


export default function DashboardAdmin({currentUser} : {currentUser: User}) {

    return (
        <>
            <Shell currentUser={currentUser}>
                <h1 className="weight-600 text-3xl mb-8">Hello {currentUser.firstName}</h1>
            </Shell>


        </>
    )
}
