import {BaseTransaction} from "../../../api/BaseTransactions/BaseTransaction.ts";

function Row({data}) {
    return (
        <tr className="text-base text-gray-900 hover:bg-gray-50 transition ease-in-out rounded-xl">
            <td className='py-2 px-4 leading-6'>
                {data.context.tax_type}
            </td>
            <td className='py-2 px-4 text-gray-500'>{data.context.country_proprietary_tax_assessment_code}</td>
            <td className='py-2 px-4 text-gray-500'>{data.context.period_type}</td>
            <td className='py-2 px-4 text-gray-500'>{data.context.paid_on}</td>
            <td className='py-2 px-4 text-gray-900'>€ {((data.flow === 'outflow' ? data.amount * -1 : data.amount)/100).toLocaleString()}</td>
        </tr>
    )
}

export default function TaxesHistory({history}) {

    return (
        <table className="border-collapse w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 rounded-xl">
            <tr className="text-base text-gray-900">
                <td className="px-4 py-2 rounded-s-md">Type</td>
                <td className="px-4 py-2">Aanslagcode</td>
                <td className="px-4 py-2">Belastingperiode</td>
                <td className="px-4 py-2">Betaald op</td>
                <td className="px-4 py-2 rounded-e-md">Bedrag</td>
            </tr>
            <tr className="h-2"></tr>
            </thead>
            <tbody>
                {history.map(data => <Row data={data}/>)}
                <tr>
                    <td colSpan={5} className="px-4 py-4 text-lg">
                        <hr/>
                    </td>
                </tr>
                <tr className="text-base text-gray-900 hover:bg-gray-50 rounded-xl ">
                <td colSpan={4} className="px-4 text-lg">
                        Totaal:
                    </td>
                    <td colSpan={5} className="px-4 text-lg">
                        € {(history.reduce((acc, item) => acc - item.amount, 0) / 100).toLocaleString()}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
