import {BackendApi} from "../api.ts";
import {CustomerBankAccount} from "./CustomerBankAccount.ts";

export class CustomerBankAccountRepository {
    private api: BackendApi;

    constructor(api: BackendApi) {
        this.api = api;
    }

    public async getAllBankAccounts(): Promise<Array<CustomerBankAccount>> {
        const rawResult = await this.api.get('customer-bank-account', {});
        const jsonResult = await rawResult.json();

        const result: CustomerBankAccount[] = [];
        for (const rawItem of jsonResult) {
            result.push(CustomerBankAccount.fromApiResponse(rawItem));
        }

        return result;
    }

    public async getMyBankAccounts(businessId: string, withLogo: boolean = true): Promise<Array<CustomerBankAccount>> {
        const rawResult = await this.api.get('business/by-bank-accounts', {business_id: businessId});
        const jsonResult = await rawResult.json();

        const result: CustomerBankAccount[] = [];
        for (const rawItem of jsonResult) {
            result.push(CustomerBankAccount.fromApiResponse(rawItem));
        }

        if (withLogo) {
            return await Promise.all(
                result.map(
                    async (bankAccount) => bankAccount.withLogoUrl(
                        await this.getBankAccountLogoURL(bankAccount.institutionName)
                    )
                )
            )
        }

        return result;
    }

    private async getBankAccountLogoURL(bankUrl: string): Promise<string> {
        const response = await this.api.get('bank/logo', {
            bank_id: bankUrl
        })

        const json = await response.json()

        return json.logo_url
    }
}
