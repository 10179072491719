import React, { useState } from 'react';
import { BackendApi } from '../../../../../api/api.ts';
import { Business } from './BusinessesOverviewPage';
import { SecondaryButton } from '../../../../../components/SecondaryButton.tsx';

interface BusinessInsightsProps {
  business: Business;
  api: BackendApi;
}

export function BusinessInsights({ business, api }: BusinessInsightsProps) {
  const [insightType, setInsightType] = useState('');
  const [insightMessage, setInsightMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async () => {
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await api.post('admin/insights/create-insight', {
        type: insightType,
        message: insightMessage,
        business_id: business.id,
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Insight sent successfully!');
        setInsightType('');
        setInsightMessage('');
      } else {
        setError(data.detail || 'Failed to send insight');
      }
    } catch (err) {
      console.error('Error sending insight:', err);
      setError('An error occurred while sending the insight');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold mb-4">Send Manual Insight</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <select
            className="w-full border border-gray-300 rounded-md p-2 text-sm"
            value={insightType}
            onChange={(e) => setInsightType(e.target.value)}
            required
          >
            <option value="">Select a type</option>
            <option value="ALERT">Alert</option>
            <option value="INSIGHT">Insight</option>
          </select>
        </div>
        <div className="mb-4">
          <textarea
            className="w-full h-32 border border-gray-300 rounded-md p-2 text-sm"
            placeholder="Write your insight here"
            value={insightMessage}
            onChange={(e) => setInsightMessage(e.target.value)}
            required
          />
        </div>
        <SecondaryButton
          title={isLoading ? 'Sending...' : 'Send Insight'}
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </form>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {success && <p className="text-green-500 mt-2">{success}</p>}
    </div>
  );
}
