interface rawData {
    counterParty: string,
    iban: string,
    description: string,
    amount: string,
    category: string | null,
}

export class BaseTransaction {
    public id: string;
    public category: string;
    public counterParty: string;
    public iban: string;
    public relationName: string;
    public description: string;
    public date: string;
    public flow: string;
    public amount: number;
    public bankAccountId: string;
    public raw: string;

    constructor(id: string, counterParty: string, iban: string, relationName: string, description: string, flow: string, amount: number, date: string, category: string = 'uncategorized', raw: string, bankAccountId: string) {
        this.id = id
        this.counterParty = counterParty
        this.iban = iban
        this.relationName = relationName
        this.description = description
        this.date = date
        this.flow = flow
        this.amount = amount
        this.category = category
        this.raw = raw
        this.bankAccountId = bankAccountId
    }

    get value(): number {
        if (this.flow === 'inflow') {
            return this.amount
        } else {
            return -this.amount
        }
    }

    public categorize(newCategory: string): this {
        this.category = newCategory
        return this
    }

    public static fromApiResponse(data: any): BaseTransaction {
        return new BaseTransaction(
            data['transaction_id'],
            data['relation_name'],
            data['relation_iban'],
            data['relation_name'],
            data.description,
            data['flow'],
            parseInt(data.amount),
            data['booking_date'],
            data.category,
            data['raw'],
            data['bank_account_id']
        )
    }
}
