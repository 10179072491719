import {User} from "../../../../api/AccessControl/User.ts";
import {useEffect, useState} from "react";
import {BackendApi} from "../../../../api/api.ts";
import LoadingFrame from "../../../../components/LoadingFrame.tsx";
import Shell from "../../Shell.tsx";
import {syntaxHighlight} from "../../baseTransactions/BaseTransactions.tsx";

function getAggregatedJobs(jobs) {
    var newArray = [];

    for (var i = 0; i < jobs.length; i++) {

        var idx = newArray.findIndex(x => x.group_id === jobs[i].group_id);

        if (idx < 0) {
            newArray.push({
                group_id: jobs[i].group_id,
                jobs: [jobs[i]]
            });
        } else {
            newArray[idx] = {
                ...newArray[idx], jobs: newArray[idx].jobs.concat(jobs[i])
            };
        }
    }

    return newArray;
}

export default function ImportJobsPage({currentUser}: { currentUser: User }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [importJobs, setImportJobs] = useState<any>(null)

    async function onLoad() {
        const api = new BackendApi(currentUser.getToken());
        api.get('get-import-jobs', {}).then(data => data.json()).then(data => setImportJobs(data)).then(() => setIsLoading(false))
    }

    useEffect(() => {
        onLoad()


        const interval = setInterval(() => {
            onLoad()
        }, 2000);

        return () => clearInterval(interval);

    }, [])


    return (
        <div>
            <Shell currentUser={currentUser} isLoading={isLoading}>

                <LoadingFrame isLoading={isLoading}>
                    <h1 className="weight-600 text-3xl mb-6 ">Import Jobs</h1>

                    <div className="min-w-full divide-y divide-gray-300">
                        <div className="flex flex-col divide-gray-200 font-mono">
                            {
                                importJobs && getAggregatedJobs(importJobs).map((group) => (
                                    <JobGroup group={group}/>
                                ))
                            }

                        </div>
                    </div>
                </LoadingFrame>
            </Shell>

        </div>

    )
}

function JobGroup({group}) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="bg-gray-50 rounded-lg mb-4 overflow-hidden">
                <div className='flex justify-between px-4 py-4 p-1 items-center'>
                    <button onClick={() => setOpen(!open)} className={(open ? 'rotate-90' : '') + ' transition'}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                        </svg>
                    </button>
                    <h2>{group.group_id}</h2>
                    <div className='min-w-32'>
                        <span>Transactions: {group.jobs.reduce((acc, job) => acc + job.total_transactions, 0)}</span><br/>
                        <span>Processed: {group.jobs.reduce((acc, job) => acc + job.processed_transactions, 0)}</span>
                    </div>

                    <div>
                        <div className='grid grid-cols-2 gap-2 px-4'>
                            <div className="flex gap-2 px-4">
                                <span>{group.jobs.filter(job => job.status === 'FINISHED').length}</span>
                                <JobStatus status='FINISHED'/>
                            </div>
                            <div className="flex gap-2 px-4">
                                <span>{group.jobs.filter(job => job.status === 'FAILED').length}</span>
                                <JobStatus status='FAILED'/>
                            </div>
                            <div className="flex gap-2 px-4">
                                <span>{group.jobs.filter(job => job.status === 'PENDING').length}</span>
                                <JobStatus status='PENDING'/>
                            </div>
                            <div className="flex gap-2 px-4">
                                <span>{group.jobs.filter(job => job.status === 'RUNNING').length}</span>
                                <JobStatus status='RUNNING'/>
                            </div>
                        </div>
                        <div className={''}>
                            <div className="w-full text-center mt-4">
                                <span>Total Jobs: {group.jobs.length}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {group.jobs.filter(job => job.status === 'PENDING').length != 0 && <div
                    className={`bg-primary w-[${(group.jobs.length / group.jobs.filter(job => job.status === 'FINISHED' || job.status === 'FAILED').length) * 100}%] h-[3px]`}>

                </div>}

            </div>

            <div className={("ml-8 flex flex-col gap-4 mb-12 transition ") + (open ? '' : ' hidden')}>
                {group.jobs.map((job) => (

                    <div key={job.id}
                         className={"bg-gray-50 rounded-lg px-4 py-4 border-2 px-4 " + (job.status === 'FAILED' ? ' border-red-500' : 'border-gray-50')}>
                        <div className="grid grid-cols-4 gap-4 items-center">
                            <div className='flex flex-col py-2 col-span-2'>
                             <span
                                 className="font-mono whitespace-nowrap text-sm text-gray-500">ID: {job.id}</span>
                                <span className="font-mono whitespace-nowrap text-sm text-gray-500">GoCardless Account ID: {job.open_banking_service_account_id}</span>
                                <span className="font-mono whitespace-nowrap text-sm text-gray-500">GoCardless Requisition ID: {job.open_banking_service_connection_id}</span>
                                <span
                                    className="font-mono whitespace-nowrap text-sm text-gray-500">STARTED AT: {job.started_at}</span>
                                <span
                                    className="font-mono whitespace-nowrap text-sm text-gray-500"> FINISHED AT: {job.finished_at}</span>
                                <span
                                    className="font-mono whitespace-nowrap text-sm text-gray-500">Total Transactions: {job.total_transactions}</span>
                                <span
                                    className="font-mono whitespace-nowrap text-sm text-gray-500">Processed Transactions {job.processed_transactions}</span>
                            </div>

                            <div className='flex flex-col py-2 px-4'>
                            <span
                                className="font-mono whitespace-nowrap text-sm text-gray-700 font-bold">{job.owner_name}</span>
                                <span
                                    className="font-mono whitespace-nowrap text-sm text-gray-500">{job.currency}</span>
                                <span
                                    className="font-mono whitespace-nowrap text-sm text-gray-500">{job.iban}</span>
                            </div>

                            <div className={'flex flex-col items-end'}>
                                <div className={(job.status === 'RUNNING' ? 'animate-spin' : '')}>
                                    <JobStatus status={job.status}/>
                                </div>
                            </div>

                        </div>
                        {job.status === 'FAILED' && <>
                            <div className="flex justify-between items-center">
                                <span className="text-sm mt-3 block">Error</span>
                                <a className='bg-primary px-2 py-1 cursor-pointer rounded-md text-white my-2' target='_blank' href={job.error_context.sentry_url}>Open in Sentry</a>
                            </div>
                            <pre
                                className="w-full  p-4 bg-gray-100 rounded-md overflow-x-auto "
                                dangerouslySetInnerHTML={{__html: syntaxHighlight(JSON.stringify(job.error_context, null, 2))}}>

                                                </pre>
                        </>}
                    </div>
                ))}
            </div>
        </>
    )
}

function JobStatus({status}) {
    if (status === 'PENDING') {
        return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
        </svg>

    }

    if (status === 'RUNNING') {
        return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="size-6 spinner">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
        </svg>

    }

    if (status === 'FINISHED') {
        return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="green" className="size-6 text-red">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
        </svg>

    }

    if (status === 'FAILED') {
        return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="red" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"/>
        </svg>

    }
}
