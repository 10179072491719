import React, { ReactNode, useState } from 'react';

// Types for Tab props
interface TabProps {
    label?: string;
    children: ReactNode;
}


interface TabsProps {
    children: ReactNode;
}

// Tabs Component
export function Tabs({ children }: TabsProps): JSX.Element {
    const [activeIndex, setActiveIndex] = useState(0);

    function handleTabClick(index: number) {
        setActiveIndex(index);
    }

    return (
        <div>
            {/* Tab Buttons */}
            <div className="flex gap-x-4">
                {React.Children.map(children, (child, index) => {
                    if (React.isValidElement<TabProps>(child)) {
                        return (

                        <button key={index} onClick={() => handleTabClick(index)} className={
                            (index === activeIndex ? 'bg-superliteprimary text-primary' : 'text-gray-500 hover:text-gray-700') + ' rounded-md px-3 py-2 text-sm font-medium'}>
                            {child.props.label || `Tab ${index + 1}`}
                        </button>
                    )
                        ;
                    }
                    return null;
                })}
            </div>

            {/* Active Tab Content */}
            <div className="mt-8">
            {React.Children.map(children, (child, index) =>
                    index === activeIndex && React.isValidElement<TabProps>(child)
                        ? child
                        : null
                )}
            </div>
        </div>
    );
}

// Tab Component
export function Tab({ label, children }: TabProps): JSX.Element {
    return <div>{children}</div>;
}
