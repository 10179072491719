import {CustomerBankAccount} from "../api/CustomerBankAccounts/CustomerBankAccount.ts";
import {Money} from "./Money.tsx";


export default function BankCard({CustomerBankAccount}: { CustomerBankAccount: CustomerBankAccount }) {

    return (
        <div className="group">

            <div
                className="border-2 border-gray-200 rounded-xl p-8 w-96 aspect-video bg-gradient-to-r from-blue-200 via-gray-200 to-primary/50  transition  hover:border-primary/40 ">
                <div className="flex justify-between">
                    <div>
                        <div>
                            <h3 className=" font-mono mt-2 text-xs">Name</h3>
                            <h2 className="uppercase font-mono">{CustomerBankAccount.ownerName}</h2>
                        </div>
                        <div>
                            <h3 className=" font-mono mt-2 text-xs">IBAN</h3>
                            <h2 className="uppercase font-mono">{CustomerBankAccount.iban}</h2>
                        </div>
                    </div>

                    <img src={CustomerBankAccount.logoUrl}
                         alt="Institution logo" className="h-20 rounded-xl mix-blend-multiply"/>
                </div>

                <div>
                    <h3 className=" font-mono mt-2 text-xs">Balance</h3>
                    <h2 className="uppercase font-mono text-2xl "><Money value={CustomerBankAccount.balance / 100}/></h2>
                </div>
            </div>
        </div>
    )
}
