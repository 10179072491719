import {BaseTransaction} from "../../../../api/BaseTransactions/BaseTransaction.ts";
import {useEffect, useState} from "react";
import {BackendApi} from "../../../../api/api.ts";
import {BaseTransactionsRepository} from "../../../../api/BaseTransactions/BaseTransactionsRepository.ts";
import Shell from "../../Shell.tsx";
import Stats from "../Stats.tsx";
import Insights from "../Insights.tsx";
import MonthlyInflowOutflowGraph from "./CashflowPage/MonthlyInflowOutflowGraph.tsx";
import Transactions from "../Trasactions.tsx";
import {User} from "../../../../api/AccessControl/User.ts";


export default function DashboardEntrepreneur({currentUser}: { currentUser: User }) {

    const greetingEmojis = ['✨', '👋', '🙌', '🚀']
    const selectedGreetingEmoji = greetingEmojis[Math.floor(Math.random() * greetingEmojis.length)];

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [transactions, setTransactions] = useState<Array<BaseTransaction>>([]);
    const [boiGraph, setBoiGraph] = useState();
    const [baseStats, setBaseStats] = useState();

    useEffect(() => {onLoad()}, []);
    async function onLoad() {
        const api = new BackendApi(currentUser.getToken())
        const transactionRepository = new BaseTransactionsRepository(api)

        transactionRepository.getBusinessBankTransactions(currentUser.businesses[0], 0, 16).then((data) => setTransactions(data.results))
        const res = await api.get('business/stats/iob-graph-data', {})
        res.json().then(data => setBoiGraph(data))

        const ress = await api.get('business/stats/base-stats', {})
        ress.json().then(data => setBaseStats(data)).then(() => {setIsLoading(false)})
    }

    return (
        <>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <div className="w-full flex items-center mb-8 justify-between">
                    <h1 className="weight-600 text-3xl">Hey {currentUser.firstName} {selectedGreetingEmoji}</h1>
                    {/*<TimeSelector defaultValue={PeriodLengthSelectionsEnum.monthly} onUpdateFunc={(selection: TimeSelection) => {*/}
                    {/*    console.log(selection)*/}
                    {/*}}/>*/}

                </div>

                <Stats data={baseStats}/>
                <div className="grid xl:grid-cols-3 grid-cols-1 auto-rows-max gap-4 mt-8">
                    <div>
                        <h3 className="text-xl font-gray-900 py-3 pt-6">Laatste insights</h3>
                        <Insights className="h-[30vh] " minimum={true}/>
                    </div>
                    <div className="col-span-2 relative">
                        <h3 className="text-xl font-gray-900 py-3 pt-6">Cashflow</h3>
                        <MonthlyInflowOutflowGraph data={boiGraph} className={"h-[30vh]"}/>
                    </div>
                </div>

                <div className="w-full mt-8">
                    <h3 className="text-xl font-gray-900 py-3 pt-6">Recente transacties</h3>

                    <div className="w-full">
                        <Transactions transactions={transactions}/>
                    </div>
                </div>
            </Shell>
        </>
    )
}
