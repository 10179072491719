import {Transition} from "@headlessui/react";
import {CheckCircleIcon} from "@heroicons/react/24/outline/index";
import {useState} from "react";
import {XCircleIcon} from "lucide-react";

interface Notification {
    title: string,
    description: string,
    type: 'success' | 'error',
    id?: string,
}

export function UseNotifications(): [Array<Notification>, (notification: Notification) => void] {
    const [notifications, setNotifications] = useState<Array<Notification>>([])

    function dispatch(notification: Notification) {
        setNotifications((prevNotifications) => [...prevNotifications, {
            title: notification.title,
            description: notification.description,
            type: notification.type,
            id: self.crypto.randomUUID()
        }])

        setTimeout(() => {
            setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== notification.id))
        }, 5000)
    }

    return [notifications, dispatch]
}

export function Notifications({notifications}: { notifications: Array<Notification> }) {

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    {notifications && notifications.map((notification) => (
                        <Transition show={!!notifications}>
                            <div
                                className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                                <div className="p-4">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            {notification.type === 'success' && <CheckCircleIcon aria-hidden="true"
                                                                                                 className="h-6 w-6 text-green-400"/>}
                                            {notification.type === 'error' &&
                                                <XCircleIcon aria-hidden="true" className="h-6 w-6 text-red-400"/>}
                                        </div>
                                        <div className="ml-3 w-0 flex-1 pt-0.5">
                                            <p className="text-sm font-medium text-gray-900">{notification.title}</p>
                                            <p className="mt-1 text-sm text-gray-500">{notification.description}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Transition>
                    ))}

                </div>
            </div>
        </>
    )
}
