import {Transaction} from "../../TransactionsTable.tsx";

export enum FilterOperation {
    EQUALS,
    NOT_EQUALS,
    GREATER_THAN,
    LESS_THAN,
    CONTAINS,
    NOT_CONTAINS,
    STARTS_WITH,
    ENDS_WITH,
    BETWEEN,
    NOT_BETWEEN,
}

export class Filter {
    id: string
    operation: FilterOperation
    columnId: string
    values: Array<any>
}

export class FilterService {

    constructor(readonly to_filter: Array<Transaction>)
    {
    }

    public apply(filters: Array<Filter>) {
        let filteredItems = this.to_filter;

        for (const filter of filters) {
            console.log('Running filtering for ', filter)

            if (filter.operation === FilterOperation.BETWEEN) {
                filteredItems = filteredItems.filter(
                    (item) =>
                        Date.parse(item.bookingDate) >= Date.parse(filter.values[0])
                        && Date.parse(item.bookingDate) <= Date.parse(filter.values[1])
                )

            }

            if (filter.operation === FilterOperation.EQUALS) {

                filteredItems = filteredItems.filter(
                    (item) => {
                        return item[filter.columnId] === filter.values[0]
                    }
                )
            }

            if (filter.operation === FilterOperation.GREATER_THAN) {
                filteredItems = filteredItems.filter(
                    (item) => {
                        return item[filter.columnId] > filter.values[0]
                    }
                )
            }

            if (filter.operation === FilterOperation.LESS_THAN) {
                filteredItems = filteredItems.filter(
                    (item) => {
                        return item[filter.columnId] < filter.values[0]
                    }
                )
            }
        }

        return filteredItems
    }
}
