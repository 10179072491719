import {useEffect, useState} from "react";
import {PhoneNumberField} from "../../components/form/PhoneNumberField.tsx";
import {FormTodo, FormTodoType} from "../../components/form/FormTodo.tsx";

export interface StepOneFormData {
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    repeat_password: string,
    phone_number: string,
}

interface Props {
    onUpdateFunc: (formData: StepOneFormData) => void
    formTodos: Array<FormTodoType>
    defaultFormData?: Object
}

export function StepOne({onUpdateFunc, formTodos, defaultFormData}: Props) {
    //@ts-ignore
    const [formData, setFormData] = useState<StepOneFormData>(defaultFormData)

    useEffect(() => {
        onUpdateFunc(formData)
    }, [formData])

    return (
        <div>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Create an account</p>
            <p className="mt-4 text-base block text-gray-600 mb-12">
                All of the basics
            </p>
            <form action="#" method="POST" className="space-y-6">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            autoComplete="email"
                            defaultValue={formData.email}
                            onChange={(e) => setFormData({...formData, email: e.target.value})}
                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        />
                        <FormTodo fieldName={'email'} formTodos={formTodos}/>
                    </div>
                </div>
                <div className="grow">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Phone number
                    </label>
                    <div className="mt-2">
                        <PhoneNumberField
                            defaultValue={formData.phone_number}
                            onChangeFunc={(value) => setFormData({...formData, phone_number: value})}
                        />
                        <FormTodo fieldName={'phone_number'} formTodos={formTodos}/>

                    </div>
                </div>

                <div className="flex w-full gap-6">
                    <div className="grow">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            First name
                        </label>
                        <div className="mt-2">
                            <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                required
                                defaultValue={formData.first_name}
                                onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                                className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <FormTodo fieldName={'first_name'} formTodos={formTodos}/>
                        </div>
                    </div>
                    <div className="grow">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Last name
                        </label>
                        <div className="mt-2">
                            <input
                                id="lastName"
                                name="lastName"
                                type="text"
                                required
                                defaultValue={formData.last_name}
                                onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                                className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <FormTodo fieldName={'last_name'} formTodos={formTodos}/>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Password
                        </label>
                    </div>
                    <div className="mt-2">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            required
                            defaultValue={formData.password}
                            onChange={(e) => setFormData({...formData, password: e.target.value})}
                            autoComplete="current-password"
                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        />
                        <FormTodo fieldName={'password'} formTodos={formTodos}/>
                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Repeat password
                        </label>
                    </div>
                    <div className="mt-2">
                        <input
                            id="passwordRepeat"
                            name="passwordRepeat"
                            type="password"
                            required
                            defaultValue={formData.repeat_password}
                            onChange={(e) => setFormData({...formData, repeat_password: e.target.value})}
                            autoComplete="current-password"
                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        />
                        <FormTodo fieldName={'repeat_password'} formTodos={formTodos}/>
                    </div>
                </div>

                <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                        <input
                            id="candidates"
                            name="candidates"
                            type="checkbox"
                            aria-describedby="candidates-description"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                    </div>
                    <div className="ml-3 text-sm/6">
                        <label htmlFor="candidates">
                            I agree with the <a href="https://pocketcfo.io/privacy-policy-2" target="_blank"
                                                className="font-semibold text-primary hover:underline">Privacy
                            Policy</a> and <a href="https://pocketcfo.io/general-terms" target="_blank"
                                              className="font-semibold text-primary hover:underline">Terms of
                            Service</a>
                        </label>

                    </div>
                </div>


            </form>
        </div>

    )
}
