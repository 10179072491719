export class BackendApi {
    private readonly basePath: string;
    public readonly token: any;

    constructor(token) {
        this.token = token

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            this.basePath = 'http://localhost:5001/api/v1/'
        } else {
            this.basePath = 'https://monorepo-72srzvk2oq-nw.a.run.app/api/v1/'
        }
    }

    public async get(path: string, queryParams: object | null = null) {

        let uri = this.basePath + path

        if (queryParams) {
            //@ts-ignore
            uri = uri + "?" + (new URLSearchParams(queryParams).toString());
        }

        return await fetch(uri, {
            headers: {Authorization: `Bearer ${this.token}`}
        });
    }

    public async post(path: string, body: Object): Promise<Response> {
        const request = new Request(this.basePath + path, {
            method: "POST",
            body: JSON.stringify(body),
        });

        return await fetch(request, {
            headers: {
                Authorization: `Bearer ${this.token}`,
                "content-type": "application/json"
            }
        });
    }
}
