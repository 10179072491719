import Shell from "../Shell.tsx";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {CustomerBankAccount} from "../../../api/CustomerBankAccounts/CustomerBankAccount.ts";
import Dropdown from "../../../components/Dropdown.tsx";
import {BackendApi} from "../../../api/api.ts";
import {CustomerBankAccountRepository} from "../../../api/CustomerBankAccounts/CustomerBankAccountRepository.ts";
import {BaseTransactionsRepository} from "../../../api/BaseTransactions/BaseTransactionsRepository.ts";
import {User} from "../../../api/AccessControl/User.ts";

export default function Export({currentUser}:{currentUser: User}) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedBankAccount, setSelectedBankAccount] = useState<CustomerBankAccount>();
    const [bankAccounts, setBankAccounts] = useState<Array<CustomerBankAccount>>([]);


    async function onLoad() {
        const api = new BackendApi(currentUser.getToken());
        const customerBankAccountRepository = new CustomerBankAccountRepository(api)

        const bankAccounts_ = await customerBankAccountRepository.getAllBankAccounts();
        setSelectedBankAccount(await bankAccounts_[0]);
        setBankAccounts(bankAccounts_);
        setIsLoading(false)
    }

    useEffect(() => {
        onLoad()
    }, []);



    return (
        <>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <h1 className="text-2xl font-semibold leading-6 text-gray-900 pb-8">Export</h1>
                <div className="p-4 bg-gray-100 rounded-md">
                    <span className="font-bold font-lg pb-4 block">Transactions</span>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            <span>Bank Account</span>
                            <Dropdown
                                selected={{
                                    value: `${selectedBankAccount?.ownerName} ${selectedBankAccount?.iban} ${selectedBankAccount?.currency}`,
                                    tailwindColor: null
                                }}
                                options={bankAccounts?.map((bankAccount: CustomerBankAccount) => {
                                    return {
                                        value: `${bankAccount?.ownerName} ${bankAccount?.iban} ${bankAccount?.currency}`,
                                        tailwindColor: null
                                    }
                                })}
                                onUpdateFunc={async (value: string) => {
                                    for (const bankAccount of bankAccounts) {
                                        if (`${bankAccount?.ownerName} ${bankAccount?.iban} ${bankAccount?.currency}` === value) {
                                            setSelectedBankAccount(bankAccount);
                                        }
                                    }
                                }
                                }/>
                        </div>
                        <div><button className="rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        onClick={() => {
                            const api = new BackendApi(currentUser.getToken());
                            api.get('export/csv/transactions', {'bank_account_id': selectedBankAccount.id}).then(response => response.blob())
                                .then(blobby => {
                                    let objectUrl = URL.createObjectURL(blobby);
                                    // @ts-ignore
                                    window.location = String(objectUrl);
                                });
                        }}
                        >Download</button></div>
                    </div>

                </div>
            </Shell>
        </>
    )
}
